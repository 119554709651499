@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.categories {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.category {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  text-shadow: 1px 1px var(--matterColor);
  text-align: center;
  font-size: 18px;
  line-height: 24px;

  /* Style */
  background-color: var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  background: var(--background-image-url);
  background-position: center;
  background-size: cover;

  /* Size */
  min-height: 200px;
  position: relative;

  /* Effect */
  transition: all var(--transitionStyle);

  &:hover {
    transform: scale(1.06);
  }

  @media (--viewportMedium) {
    border: 1px solid var(--matterColorAnti);
    min-height: 300px;
  }
}
