@import '../../styles/customMediaQueries.css';

.root {
}

.reviews {
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 12px;
  margin: 0;

  @media (--viewportLarge) {
    gap: 0;
  }
}

.reviews {
  display: flex;
}

.reviewItem {
  @media (--viewportLarge) {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.review {
  /* Layout */
  display: flex;
  gap: 12px;

  /* Size */
  padding: 12px;

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    gap: 18px;
    padding: 24px 24px 18px;
  }

  @media (--viewportLarge) {
    flex-shrink: 1;
    flex-grow: 0;
  }
}

.featuredReview {
  box-shadow: var(--boxShadowBottomForm);
}

.avatar {
  height: 42px;
  width: 42px;

  flex-shrink: 0;
}

.reviewContent {
  margin-top: -2px;
}

.reviewBio {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;

  & p {
    display: inline;
  }
}

.reviewInfo {
  display: flex;
  flex-direction: column;
}

.reviewRating {
  display: flex;
  align-items: center;
}

.reviewRatingText {
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
}

.reviewRatingCount {
  color: var(--matterColor);
}

.reviewRatingStar {
  height: 16px;
  width: 16px;

  margin-top: -2px;
  margin-right: 6px;
}

.reviewInfoDetails {
  composes: h5 from global;
  color: var(--matterColorAnti);

  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;

  margin-top: 0;
  margin-bottom: 0;
}

.reviewShowMore {
  /* Font */
  composes: a from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  text-decoration: underline;
  text-transform: lowercase;

  & svg {
    display: none;
  }
}

.reviewThumbButtons {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;

  margin-top: 4px;
}

.reviewThumbButtonContainer {
  /* Layout */
  display: flex;
  align-items: center;
}

.reviewThumbButton {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  /* Size */
  height: 32px;
  width: 32px;

  /* Style */
  border-radius: calc(var(--borderRadiusMedium) * 2);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorLight);
  }
}

.reviewThumbCount {
  color: var(--matterColorAnti);
}

.reviewThumbIcon {
  flex-shrink: 0;
}

.repliesButton {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Size */
  height: 36px;
  padding: 0 16px;
  border-radius: calc(var(--borderRadiusMedium) * 2 + 2px);

  /* Font */
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);

  margin-top: 8px;

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColorLight);
    text-decoration: none;
  }
}

.repliesButtonIcon {
  /* Color */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  /* Size */
  height: 8px;
  width: auto;
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.userDisplayName {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.reviewDate {
  font-weight: var(--fontWeightRegular);
}

.featuredReviewTextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featuredReviewText {
  /* Font */
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);

  /* Style */
  padding: 0 8px;
  background: var(--marketplaceColor);
  border-radius: var(--borderRadius);
}
