.root {
  position: relative;
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 12px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-radius: 12px;
  transition: all 0.15s ease-in-out;
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  border: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
}

.outsideClickHandler:last-child .listingButton {
  border-left: 1px solid var(--matterColorNegative);
}

.outsideClickHandlerOpen .listingButton,
.outsideClickHandlerOpen + .outsideClickHandler .listingButton {
  border-left-color: transparent !important;
}

.listingButton {
  /* Style */
  background: transparent;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  outline: none;

  /* Size */
  width: 100%;
  padding: 0;
}

.listingButtonWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  /* Style */
  background-color: var(--matterColorLight);
  border: 2px solid transparent;
  border-radius: 12px;
}

.listingButtonWrapperOpen {
  border-color: var(--matterColor);
}

.listingButtonLabelWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;

  /* Font */
  text-align: left;
}

.listingButtonLabel {
  /* Font */
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: -0.1px;
  text-transform: uppercase;

  /* Remove default margins from font */
  padding: 0;
  margin: 0;
}

.listingButtonLabelText {
  /* Font */
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.1px;
}

.listingButtonIconOpen {
  transform: rotate(180deg);
}
