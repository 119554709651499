@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);

  width: 70%;
  height: 70%;
}

.loadingImageIcon {
  height: 48px;
  width: 48px;
}
