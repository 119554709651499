@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Style */
  border-radius: var(--borderRadiusMedium);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 12px;
  min-height: 72px;

  /* Style */
  background-color: var(--matterColorLight);
  border-left: 1px solid var(--matterColorNegative);
  border-right: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
