.root {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.wrapper {
  position: relative;
}

.rootForVideo {
  /* Layout - video will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fallbackWhileDownloading {
  /* Layout */
  display: block;
  position: absolute;
  width: 100%;
  margin: 0;
  overflow: hidden;

  /* Style */
  background-color: var(--matterColorNegative);
}

.spinnerIcon {
  width: 28px;
  height: 28px;

  stroke: var(--marketplaceVariationColor);
  stroke-width: 3px;
}
