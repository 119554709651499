@import '../../styles/customMediaQueries.css';

:root {
  --bgColor: #4a4a4a;
}

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  z-index: 999999;

  /* Dimensions */
  width: 100vw;
  padding: 12px 24px;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--bgColor);
  color: var(--matterColorNegative);
}

.message {
  composes: h5 from global;
  margin: 0;
}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 8px 12px;
  margin: 0 0 0 24px; /* Margin left  = 24px */

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }
}
