@import '../../styles/customMediaQueries.css';

:root {
  --buttonRootHeight: 77px;
}

.root {
  /* Dimensions */
  width: 100%;
  height: 100%;
  padding-top: 32px;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportLarge) {
    padding-top: 0;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 42px;

    margin-bottom: 44px;
  }
}

.content {
  height: calc(100% - var(--buttonRootHeight));
  overflow-y: auto;
  padding: 0 24px calc(var(--buttonRootHeight) + 48px);

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

.contentWrapper {
  max-width: var(--EditListingPage_panelWidth);
  width: 100%;
  margin: 0 auto;
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;

  & > div {
    display: none;
  }
}

.priceError {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--failColor);
  overflow: hidden;
}

.field {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.ticketPriceInputs {
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius);
}

/* Price input */
.ticketPriceInputs input,
.ticketPriceInputs select {
  border-color: transparent;
  transition: all var(--transitionStyleButton);

  &:hover,
  &:focus {
    border-color: var(--matterColor);
  }
}

.ticketPriceInputs input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid var(--matterColorNegative);
}

.ticketPriceInputs select,
.ticketPriceInputs select {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ticketAvailabilityItem {
  padding: 0;
  margin-top: 6px;

  & label {
    padding: 0;
  }
}

.ticketAvailabilityChecked {
  stroke: var(--marketplaceVariationColor);
  fill: var(--marketplaceVariationColor);
}

.submitAndPreviewButton {
  display: flex;
  align-items: center;
}

.submitButtonRoot {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* Size */
  width: 100%;
  padding: 16px 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    position: absolute;
    padding: 20px 24px;
  }
}

.backButton {
  composes: editWizardButtonRoot from '../../components/Button/Button.module.css';

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);

  /* Font */
  color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorBright);
    color: var(--matterColor);
    text-decoration: none;
  }
}
