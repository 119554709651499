/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/

.logoIcon {
  fill: var(--marketplaceColor);
}
