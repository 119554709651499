@import '../../styles/customMediaQueries.css';

:root {
  --buttonRootHeight: 77px;
}

.root {
  /* Dimensions */
  width: 100%;
  height: 100%;
  padding-top: 32px;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportLarge) {
    padding-top: 0;
  }
}

.title {
  /* Font  */
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    /* Font  */
    font-size: 32px;
    line-height: 42px;

    /* Margin */
    margin-bottom: 44px;

    /* Width */
    max-width: var(--EditListingPage_panelWidth);
  }
}

.error {
  color: var(--failColor);
}

.content {
  height: calc(100% - var(--buttonRootHeight));
  overflow-y: auto;
  padding: 0 24px calc(var(--buttonRootHeight) + 48px);

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

.contentWrapper {
  max-width: var(--EditListingPage_teamPanelWidth);
  width: 100%;
  margin: 0 auto;
}

.formMargins {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.field {
  composes: formMargins;
}

.fieldsFlex {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  /* Margins */
  composes: formMargins;

  & .field {
    flex: 1;
    width: calc(50% - 12px);
    margin: 0;
  }
}

.projectDatesField {
  margin-top: 8px;
}

.bookingDates {
  composes: formMargins;
}

.locationAutocompleteInputIcon {
  display: none;
}

.locationAutocompleteInput {
  padding-left: 12px;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceVariationColor);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  min-width: 400px;

  @media (--viewportMedium) {
    top: 52px;
  }
}

.backButton {
  composes: editWizardButtonRoot from '../../components/Button/Button.module.css';

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);

  /* Font */
  color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorBright);
    color: var(--matterColor);
    text-decoration: none;
  }
}

.submitAndPreviewButton {
  display: flex;
  align-items: center;
}

.submitButtonRoot {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* Size */
  width: 100%;
  padding: 16px 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    position: absolute;
    padding: 20px 24px;
  }
}

.submitButton {
}
