.root {
  display: flex;
  align-items: center;
}

.icon path {
  fill: transparent;
}

.followingIcon path {
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor) !important;
}

.spinner {
  height: 20px;
  width: 20px;
}
