@import '../../styles/customMediaQueries.css';

.root,
.form {
  display: flex;
  flex-direction: column;
}

.card {
  composes: marketplaceInputStyles from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  height: 38px;

  /* Border */
  border-color: var(--attentionColor);

  & > div {
    width: 100%;
  }

  @media (--viewportMedium) {
    height: 44px;
  }
}

.cardSuccess {
  border-color: var(--successColor);
}

.cardError {
  border-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.taxErrorMessage {
  margin-bottom: 0;
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  composes: h5 from global;

  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);
  line-height: 24px;

  display: inline-block;
  padding: 0px 0 0px 24px;
  margin: 2px 0 0 0;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.paymentAddressField {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.taxContainer {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.billingHeading,
.taxHeading {
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--matterColorAnti);

  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.taxHeading {
  margin-bottom: 12px;
}

.taxDescription {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;

  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);
  text-align: center;
  line-height: 24px;

  margin: 0;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.calculateTaxButton {
  /* Layout */
  flex: 1;
  margin-top: 24px;

  /* Size */
  min-height: 52px;
  height: 52px;
  width: calc(50% - 12px);

  /* Font */
  text-transform: capitalize;

  /* Style */
  background-color: var(--matterColorBright);
}
