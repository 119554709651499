@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  & label,
  & legend {
    color: var(--matterColor);
  }
}

.card {
  composes: marketplaceInputStyles from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  height: 38px;

  /* Border */
  border-color: var(--attentionColor);

  & > div {
    width: 100%;
  }

  @media (--viewportMedium) {
    height: 44px;
  }
}

.cardSuccess {
  border-color: var(--successColor);
}

.cardError {
  border-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 14px 0;
  color: var(--matterColor);

  padding-top: 8px;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.billingHeading {
  color: var(--matterColor);

  padding: 0;
  margin: 0 0 12px 0;
}

.paymentLabel {
  margin: 0;
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 40px 0 14px 0;

  padding-top: 4px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    margin: 41px 0 26px 0;
  }
  @media (--viewportLarge) {
    margin: 40px 0 26px 0;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;
  margin-top: 48px;
}

.infoText {
  composes: h5 from global;

  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  padding: 0;
  margin: 14px 0 0 0;
}

.submitButton {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;

  /* Size */
  min-height: 56px;
}

.missingStripeKey {
  color: var(--failColor);
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.postalCode {
  margin-top: 24px;
  width: calc(40% - 9px);
}

.city {
  margin-top: 24px;
  width: calc(60% - 9px);
}

.field {
  margin-top: 24px;
}
