@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: repeat(2, 1fr);
  }

  & .field {
    margin-top: 0;
  }
}

.field {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 32px;
  }
}

.success,
.error {
  /* Hide since we're not showing any messages */
  display: none;

  margin-top: 12px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: -24px;
  }
}

.success {
  color: var(--successColor);
}

.error {
  color: var(--failColor);
}

.submitButton {
  /* Font */
  text-transform: capitalize;

  width: 170px;
  margin-top: 24px;
  margin-left: auto;

  /* Style */
  background-color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 32px;

    &:hover {
      background-color: var(--matterColor);
      opacity: 0.8;

      &:disabled {
        background-color: var(--matterColorNegative);
        opacity: 1;
      }
    }
  }
}

.submitButtonReady,
.submitButtonInProgress {
  background-color: var(--matterColor) !important;
}
