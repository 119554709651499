.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;

  /* Padding */
  padding: 12px 18px;

  /* Border */
  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 18px;
}

.userAvatar {
  height: 48px;
  width: 48px;
}

.userText {
  display: flex;
  flex-direction: column;
}

.userDisplayName {
  font-weight: var(--fontWeightMedium);
}

.userAttributes {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);

  /* Limit to 1 line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  /* Margin */
  margin-top: 0;
  margin-bottom: 0;
}

.dotDivider {
  margin: 0 5px;
}

.userButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.userButton {
  /* Font */
  font-size: 14px;
  line-height: 18px;

  /* Size */
  min-height: 42px;
  height: 42px;
  width: fit-content;
  max-width: 190px;

  /* Margin & Padding */
  padding: 0 18px;
}

.resendButton {
  background-color: var(--matterColorNegative);
}
