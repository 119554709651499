.root {
  height: 18px;
  width: 18px;

  stroke-width: 2;
}

.root path {
  fill: transparent;
}

.rootFilled path {
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}
