@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  /* Style */
  height: auto;
  padding: 12px 12px 0 0;
}

.menuLabel {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: 32px;
  width: 32px;

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 50%;
  color: var(--matterColor);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorBright);
  }
}

.menuContent {
  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowLight);
  border-radius: var(--borderRadiusMedium);

  /* Layout */
  margin-top: 6px;
  padding-top: 12px;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    width: unset !important;
    right: 12px !important;
    left: unset !important;
  }
}

.menuItem {
  /* Layout */
  display: flex;
  align-items: flex-start;
}

.menuItemButton {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  text-decoration: none !important;
  text-align: left;

  /* Layout */
  padding: 6px 16px;
  min-width: 120px;

  margin-top: 0;
  margin-bottom: 0;

  /* Effects */
  transition: var(--transitionStyleButton);
  cursor: pointer;

  &:hover {
    background-color: var(--matterColorNegative);
  }
}
