@import '../../styles/customMediaQueries.css';

.root {
  position: relative;

  /* Layout */
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

/* ================ Typography ================ */

.listingLink {
  color: var(--marketplaceVariationColor);
}
