@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  /* Style */
  padding: 12px;
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    gap: 18px;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.listingImage {
  min-width: 100px;
  max-width: 100px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadiusMedium);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.detailsWrapper {
  margin-top: 8px;
}

.details:nth-child(2) {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.details,
.detailItem {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);
}

.detailItem {
  &:not(:last-child) {
    &:after {
      content: '/';
      margin: 0 4px;
    }
  }
}

.detailItemCapitalized {
  text-transform: capitalize;
}

.reviews {
  display: flex;
  align-items: center;
}

.reviewsIcon {
  height: 14px;
  width: 14px;

  margin-top: -2px;
  margin-right: 5px;

  fill: var(--attentionColor);
}

.reviewsText {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

.organizationName {
  text-decoration: underline;
}
