.root {
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
}

.list {
  /* Position */
  margin: 0;
  padding-left: 24px;

  list-style: initial;
}

.listItem {
  /* Font */
  color: var(--matterColor);
}
