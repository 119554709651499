@import '../../styles/customMediaQueries.css';

.root {
  position: relative;

  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.listingLink {
  color: var(--marketplaceVariationColor);
}
