@import '../../styles/customMediaQueries.css';

/* Desktop */
.root {
  width: 100%;
  height: var(--topbarHeightDesktop);
  background-color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  composes: marketplacePageWidth from global;
}

.contentWithWidth {
  width: 100%;
  max-width: var(--marketplacePageWidth);
  padding: 0 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: auto;
}

.dashboardLogoContainer {
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Style */
  border-right: 1px solid var(--matterColorNegative);

  /* Size */
  height: var(--topbarHeightDesktop);
  width: var(--dashboardSideNavWidth);
  max-width: var(--dashboardSideNavWidth);
}

.logoLink {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 34px;
  width: auto;
}

.dashboardLogo {
  height: 32px;
}

.navigationLinks {
  display: flex;
  flex: 1;
}

.navigationLinksContent {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navigationLink {
  composes: h4 from global;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightRegular);

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }

    & .menuItemBorderArtist {
      background-color: var(--marketplaceVariationColor);
    }
  }
}

.navigationLinkBold {
  font-weight: var(--fontWeightMedium);
}

.mainNavigationLink {
  /* Font */
  composes: h5 from global;
  composes: noLinkUnderline from global;
  color: var(--matterColor);

  margin: 0 12px 0 0;
  padding: 12px;

  /* Style */
  border-radius: var(--topbarButtonBorderRadius);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegativeLight);
    color: var(--matterColorDark);
  }
}

.businessLink {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
}

.currentPage {
  color: var(--matterColorDark);
}

.currentPage .menuItemBorder {
  width: 6px;
  background-color: var(--matterColorDark);
}

/* Menu */
.profileMenuLabel {
  /* Layout */
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 15px 0;

  /* Size */
  height: 42px;
  padding: 5px 5px 5px 12px;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--topbarButtonBorderRadius);

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColor);
  }
}

.profileMenuIsOpen {
  border-color: var(--matterColor);
}

.menuIconContainer {
  margin-right: 12px;
}

.menuIcon {
  height: 100%;
  width: 100%;
}

.avatarContainer {
  /* Layout */
  display: flex;
  align-items: center;

  /* Position */
  position: relative;
}

.avatarNotificationDot {
  /* Layout */
  display: block;
  height: 8px;
  width: 8px;

  /* Style */
  background: var(--marketplaceColor);
  border: 1px solid var(--matterColorLight);
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 0;
  right: 0;
}

.avatar {
  height: 30px;
  width: 30px;
}

.avatarIconContainer {
  height: 30px;
  width: 30px;
}

.avatarIcon {
  height: 100%;
  width: 100%;
}

.profileMenuContent,
.profileMenuUnauthorizedContent {
  top: calc(var(--topbarHeightDesktop) - 1px);
  min-width: 270px;
  max-width: 270px;
  width: 270px;
  padding-top: 16px;

  box-shadow: var(--boxShadowLight);
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
}

.profileMenuContent {
  padding-bottom: 16px;
}

.menuItemBorder {
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuItemBorderArtist {
  background-color: var(--marketplaceVariationColor);
}

.logoutButton {
  border: 0;
  outline: 0;
  cursor: pointer;
  color: var(--matterColorAnti);
}

.helpButton {
  display: block;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
      border-bottom-left-radius: var(--borderRadiusMedium);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.notificationBadge {
  top: 0;
}
