.root {
  display: flex;
  align-items: center;
  gap: 6px;
}

.badgeImg {
  /* Size */
  height: 22px;
}

.badgeIcon {
  height: 21px;
  width: 26px;
}
