@import '../../styles/customMediaQueries.css';

.header {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.title {
  /* Font */
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;
}

.sliderItem {
  @media (--viewportLarge) {
    &:first-child {
      margin-left: 0;
    }
  }
}
