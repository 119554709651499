@import '../../styles/customMediaQueries.css';

:root {
  --pageContentPadding: 48px;
}

.root {
  max-width: 800px;
  width: 100%;

  margin-top: 48px;
  padding-bottom: 72px;
}

.formContent {
  margin-bottom: 24px;
}

.formTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.formText {
  /* Font */
  font-size: 14px;
  line-height: 24px;

  margin-top: 12px;
  margin-bottom: 0;
}

.organizationProfiles {
  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
}

.editOrganizationProfiles {
  display: flex;
  flex-direction: column;

  border: none;
  border-radius: unset;
}

.organizationProfile {
  display: flex;
  align-items: center;
  gap: 12px;

  padding: 16px 24px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.createOrganizationProfile {
  cursor: pointer;
}

.organizationProfileText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}

.organizationProfileImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.organizationProfileNoImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  /* Style */
  composes: organizationProfileImage;
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceVariationColor) 0%,
    var(--marketplaceVariationColorDark) 100%
  );
}

.organizationProfileNoImageInitials {
  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
}

.organizationProfileTitleWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 6px;

  @media (--viewportMedium) {
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
}

.organizationProfileTitle,
.organizationProfileBio {
  font-size: 14px;
  line-height: 17px;
  font-weight: var(--fontWeightSemiBold);

  margin: 0;
}

.organizationProfileBio {
  /* Font */
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);

  /* Maximum width */
  max-width: 450px;

  /* Limit to only one line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.organizationProfileSelectedText {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-size: 12px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightRegular);
  text-transform: uppercase;

  /* Layout */
  padding: 0px 4px;
  margin: 0;

  /* Style */
  background-color: var(--marketplaceVariationColor);
  border-radius: 4px;
}

.organizationProfileButton {
  margin-left: auto;
}

.editOrganizationProfile {
  padding: 0;
}

.editOrganizationProfileSection {
  display: flex;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 48px;
  }
}

.editOrganizationProfileSectionTitle {
  /* Style */
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--marketplaceVariationColor);

  margin-top: 0;
  margin-bottom: 48px;
  padding-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.editOrganizationProfileSectionContent {
  flex: 1;

  @media (--viewportMedium) {
    margin-top: 1px;
  }
}

.editOrganizationProfileSectionIcon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-right: 48px;

    /* Size */
    height: 48px;
    width: 48px;
  }
}

.editOrganizationProfileFields {
  display: flex;
  flex-direction: column;
}

.editOrganizationProfileField:not(:last-child) {
  margin-bottom: 24px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.addOrganizationProfileButton {
  /* Font */
  font-size: 14px;
  line-height: 17px;

  /* Size */
  padding: 15px 18px;
  height: auto;
  min-height: auto;
  width: auto;

  margin-left: auto;
  margin-bottom: 24px;
}

.editOrganizationProfileFooter {
  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;

  /* Size */
  width: 100%;
  padding: 16px 24px 16px calc(var(--dashboardSideNavWidth) + var(--pageContentPadding));

  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
}

.editOrganizationProfileButtonsWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  /* Size */
  max-width: 800px;
  width: 100%;
}

.editOrganizationProfileCancelButton {
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);
  color: var(--matterColor);

  &:hover,
  &:focus {
    background-color: var(--matterColorBright);
    color: var(--matterColor);
  }
}

.editOrganizationProfileSubmitButton {
  margin: 0;
}

.menu {
  height: auto;
  margin-left: auto;
}

.menuLabel {
  /* Layout */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 50%;
  color: var(--matterColor);

  & svg {
    pointer-events: none;
  }

  &:hover {
    border-color: var(--matterColor);
  }
}

.menuContent {
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  box-shadow: none;
  border-radius: var(--borderRadiusMedium);

  margin-top: 6px;
  padding: 12px 0;

  @media (max-width: 768px) {
    max-width: 130px;
    right: 0 !important;
    left: unset !important;
  }
}

.menuItem {
  display: flex;
  align-items: flex-start;
}

.menuItemButton {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  text-decoration: none !important;
  text-align: left;

  /* Layout */
  padding: 6px 16px;
  min-width: 120px;

  margin-top: 0;
  margin-bottom: 0;

  /* Effects */
  transition: var(--transitionStyleButton);
  cursor: pointer;

  & span {
    pointer-events: none;
  }

  &:hover {
    background-color: var(--matterColorBright);
  }
}

.editOrganizationProfileImageWrapper {
}

.editOrganizationProfileImageInput {
  display: none;
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileImageForm_avatarSize);
  height: var(--ProfileImageForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileImageForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileImageForm_avatarSizeDesktop);
    height: var(--ProfileImageForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileImageForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  /* Positioning: right */
  position: absolute;
  bottom: -12px;
  right: auto;
  /* Dimensions */
  padding: 12px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  border: solid 1px var(--matterColorNegative);
  border-radius: var(--borderRadius);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
  }

  &:hover {
    border: solid 1px var(--matterColor);
  }
}

.changeAvatarIcon {
  flex-shrink: 0;

  /* Size */
  height: 16px;
  width: 16px;

  & path {
    stroke-width: 1.2;
  }
}

.uploadedImage {
  /* Dimensions */
  width: var(--ProfileImageForm_avatarSize);
  height: var(--ProfileImageForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileImageForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileImageForm_avatarSizeDesktop);
    height: var(--ProfileImageForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileImageForm_avatarSizeDesktop) / 2);
  }
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}
