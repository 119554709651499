@import '../../styles/customMediaQueries.css';

.root {
  @media (--viewportLarge) {
    /* Spacing */
    margin-top: 24px;
    padding-bottom: 48px;

    /* Position */
    position: sticky;
    top: calc(var(--topbarHeightDesktop) + 24px);

    /* The calendar needs 312px */
    flex-basis: 420px;
    flex-shrink: 0;
  }

  @media (--viewportLargeWithPaddings) {
    margin-top: 48px;
  }
}

.bookingPanelBox {
  @media (--viewportLarge) {
    padding: 32px;

    /* Style */
    background: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 12px;
  }
}

.error,
.noTimeslots {
  color: var(--failColor);

  margin-top: 0;
  margin-bottom: 0;
}

.noTimeslots {
  color: var(--matterColor);
}

/* PanelButtons */
.panelButtons {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  @media (--viewportLarge) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.panelButton {
  /* Style */
  border-radius: var(--borderRadiusMedium);

  padding: 12px 24px;
  @media (--viewportMedium) {
    padding: 16px 24px;
  }
}

.donateButton {
  border-color: var(--matterColor);

  &:hover {
    border-color: var(--matterColor);
    background-color: var(--matterColorBright);
  }
}

/* PanelFilters */
.panelFilters {
  margin-bottom: 24px;
}

.filtersTitle {
  /* Font */
  font-size: 24px;
  line-height: 28px;

  /* Margin */
  margin-top: 0;
  margin-bottom: 24px;
}

.filtersWrapper {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr 1fr;

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 12px;
}

.filterButton {
  width: 100%;
}

/* PanelTimeslots */
.panelTimeslots {
  display: flex;
  flex-direction: column;
}

.timeslots {
  display: flex;
  flex-direction: column;
}

.timeslot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  &:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.timeslotInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.timeslotTitle {
  font-size: 15px;
  font-weight: var(--fontWeightMedium);

  /* Text layout */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.timeslotDates {
  composes: timeslotDate from global;
}

.timeslotButton {
  composes: timeslotButton from global;

  &:disabled {
    background: var(--matterColorNegative);
  }
}

.showMoreTimeslotsButton {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  width: 100%;
  margin-top: 32px;

  /* Style */
  border-radius: var(--borderRadiusMedium);
  border-color: var(--matterColor);

  &:hover {
    border-color: var(--matterColor);
  }
}

.spinner {
  margin: 0 auto;
}

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalContent {
  @media (max-width: 720px) {
    padding: 72px 24px 24px;
  }
}

/* OpenBookingForm */
.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 16px 24px;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  & .panelButtons {
    /* Layout */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.openBookingButton {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  text-decoration: underline;
  white-space: nowrap;
}

.premiumIcon {
  height: 18px;
  width: 18px;

  margin-top: -4px;
  margin-right: 6px;

  & path {
    fill: var(--attentionColor);
  }
}

.closedListing {
  margin-top: 0;
  margin-bottom: 0;
}
