@import '../../styles/customMediaQueries.css';

:root {
  --buttonRootHeight: 77px;
}

.root {
  /* Dimensions */
  width: 100%;
  height: 100%;
  padding-top: 32px;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportLarge) {
    padding-top: 0;
  }
}

.title {
  /* Font */
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 42px;
  }
}

.subTitle {
  /* Font */
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorAnti);

  margin-top: 6px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
    line-height: 32px;
  }
}

.tabHeading {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;
}

.content {
  height: calc(100% - var(--buttonRootHeight));
  padding: 0 24px calc(var(--buttonRootHeight) + 48px);
  overflow-y: auto;

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

.contentWrapper {
  max-width: var(--EditListingPage_panelWidth);
  width: 100%;
  margin: 0 auto;
}

.filesField {
  display: flex;
}

.filesFieldArray {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

.thumbnail {
  width: 100%;
  background-color: var(--matterColorNegative);
  border-radius: var(--borderRadius);
}

.loadingFallback {
  background-color: var(--matterColorBright);
  border-radius: var(--borderRadius);
}

.addFileWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.addFile {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 0 12px;

  /* Font */
  text-align: center;

  /* Colors */
  background-color: var(--matterColorLight);

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: var(--borderRadius);

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColor);

    & .chooseFileIcon path {
      stroke: var(--matterColor);
    }
  }
}

.addFileInput {
  display: none;
}

.chooseFileText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseFileTextWithFiles {
  & .chooseFile,
  & .fileTypes {
    display: none;
  }

  & .chooseFileIcon {
    /* Size */
    height: 48px;
    width: 48px;

    & path {
      stroke: var(--matterColorAnti);
    }
  }
}

.chooseFileIcon {
  height: 60px;
  width: 60px;

  /* Effect */
  transition: var(--transitionStyleButton);

  & path {
    stroke: var(--matterColor);

    /* Effect */
    transition: var(--transitionStyleButton);
  }
}

.chooseFile {
  composes: h3 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 12px;
  margin-bottom: 6px;
}

.fileTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  /* Font */
  color: var(--failColor);

  margin-top: 24px;
  margin-bottom: 0;
}

.arrayError {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--failColor);
  width: 100%;
  clear: both;

  margin-top: 6px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.listRenderer {
  composes: contentWrapper;

  /* Layout */
  margin-top: 32px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.submitButtonRoot {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* Size */
  width: 100%;
  padding: 16px 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    position: absolute;
    padding: 20px 24px;
  }
}

.submitButtonRootFlex {
  /* Layout */
  display: flex;
  align-items: center;
}

.backButton {
  composes: editWizardButtonRoot from '../../components/Button/Button.module.css';

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);

  /* Font */
  color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorBright);
    color: var(--matterColor);
    text-decoration: none;
  }
}
