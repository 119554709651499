@import '../../styles/customMediaQueries.css';

.root {
  /* Position */
  position: relative;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  /* Style */
  border-radius: var(--borderRadiusMedium);
}

.rootForImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  /* Style */
  object-fit: cover;
  border-radius: var(--borderRadiusMedium);
}

.listingInfo {
  /* Layout */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.imageOverlay {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  /* Style */
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
  border-radius: var(--borderRadiusMedium);
}

.info {
  /* Position */
  position: absolute;
  bottom: 0;

  /* Style */
  border-radius: var(--borderRadiusMedium);

  /* Layout */
  padding: 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 48px;
  }

  @media (--viewportLargeWithPaddings) {
    /* Position */
    top: 50%;
    left: 0;
    bottom: unset;
    transform: translate(0, -50%);

    padding: 72px 48px;
  }
}

.title {
  /* Font */
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subTitle {
  /* Font */
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 12px;
}

.details {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.detail {
  /* Font */
  color: var(--matterColorLight);

  &:not(:last-child) {
    &:after {
      content: '/';
      margin: 0 4px;
    }
  }
}

.extraInfo {
  /* Layout */
  display: flex;
  flex-direction: column;

  margin-top: 12px;

  @media (--viewportMedium) {
    align-items: center;
    flex-direction: row;
  }
}

.extraInfoText {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 0;
}

.extraInfoImg {
  /* Size */
  height: 16px;
  width: 16px;

  margin-right: 5px;
}

.reviews {
  /* Layout */
  display: flex;
  align-items: center;

  margin-right: 24px;
}

.reviewsIcon {
  /* Size */
  height: 16px;
  width: 16px;

  margin-top: -2px;
  margin-right: 5px;

  fill: var(--attentionColor);
}

.reviewsText {
  /* Font */
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 0;
}

.listingLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 48px;
}

.listingLink {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Reset */
  width: fit-content;
  padding: 12px 18px;
  background-color: var(--matterColorLight);
  color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
  }
}

.listingOrder {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    align-items: center;

    /* Font */
    color: var(--matterColorAnti);
    line-height: 1;

    &:before {
      content: '\0023';
    }
  }
}

.watchlistButton {
  left: 24px;

  @media (--viewportMedium) {
    left: 48px;
  }
}
