@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;

  /* Layout */
  width: 100%;
  height: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.tabsTitle {
  display: none;

  @media (--viewportLarge) {
    display: block;

    max-width: var(--TabNav_editListingLinkWidth);
    line-height: 29px;
  }
}

.nav {
  /* Layout */

  padding: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
    border-right: 1px solid var(--matterColorNegative);
    border-top: none;
    padding: 24px;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;

    & .tabLink {
      margin-bottom: 0;
    }
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;

  @media (--viewportLarge) {
    /* Style */
    background-color: var(--matterColorLight);
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;

  margin-bottom: 24px;
  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
