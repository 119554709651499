@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  /* Size */
  max-width: 800px;

  /* Font */
  text-align: center;
}

.title {
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.subTitle {
  max-width: 650px;

  margin-top: 0;
  margin-bottom: 0;
}

.exploreItems {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Margin */
  margin-top: 32px;
}

.exploreItem {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.exploreItemOrder {
  text-decoration: underline;

  margin-top: 0;
  margin-bottom: 0;
}

.exploreItemLabel {
  font-weight: var(--fontWeightMedium);

  margin-top: 12px;
  margin-bottom: 0;
}
