@import '../../styles/customMediaQueries.css';

:root {
  --inputHeight: 50px;
  --inputWidth: 280px;

  --submitButtonIconWidth: 38px;
}

.root {
  background-color: var(--marketplaceFooterColor);
}

.content {
  composes: marketplacePageWidth from global;
}

.fontStyles {
  /* Font */
  font-size: 14px;
  line-height: 24px;
}

.titleFontStyles {
  /* Font */
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorLight);
}

.mainContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;

  padding-top: 48px;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

.newsletter {
}

.newsletterTitle {
  /* Font */
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 24px;
}

.newsletterSuccessMessage {
  /* Font */
  composes: fontStyles;
  color: var(--successColor);

  margin-top: 0;
  margin-bottom: 0;

  max-width: 350px;
}

.newsletterErrorMessage {
  /* Font */
  composes: fontStyles;
  color: var(--failColor);

  margin-top: 12px;
  margin-bottom: 0;

  max-width: 350px;
}

.emailForm {
  /* Layout */
  display: flex;
  align-items: center;
  position: relative;
}

.emailInput {
  composes: marketplaceInputFontStyles from global;
  composes: fontStyles;

  /* Size */
  height: var(--inputHeight);
  width: var(--inputWidth);
  padding: 0 calc(30px + var(--submitButtonIconWidth)) 0 16px;

  /* Style */
  border: none;
  box-shadow: none;
  border-radius: 20px;
}

.submitButton {
  /* Position */
  position: absolute;
  right: 0;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px 0 12px;

  /* Style */
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  /* Size */
  height: var(--inputHeight);
}

.submitButtonIcon {
  width: var(--submitButtonIconWidth);
}
.submitButtonIcon path {
  fill: var(--marketplaceFooterColor);
}

.linkSections {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 72px;
  }

  @media (--viewportLarge) {
    gap: 96px;
  }
}

.linkColumnTitle {
  /* Font */
  composes: titleFontStyles;

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.linkColumnList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.linkColumnListItem {
  /* Font */
  font-size: 14px;
  line-height: 32px;
  color: var(--matterColorLight);
}

.linkColumnListItemLink {
  color: var(--matterColorLight);
}

.trustedBy {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 48px;
}

.trustedByTitle {
  /* Font */
  composes: titleFontStyles;

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.trustedByContent {
  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.trustedByTextContent {
  /* Layout */
  display: flex;
  align-items: center;

  &:after {
    @media (--viewportMedium) {
      content: '';
      height: 100%;
      width: 1px;
      background-color: var(--matterColorLight);
    }
  }
}

.trustedByText {
  /* Font */
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColorLight);

  /* Size */
  max-width: 130px;

  margin-top: 0;
  margin-bottom: 0;

  &:first-child {
    margin-right: 12px;
  }
}

.trustedByIcon {
  width: 100%;
  max-width: 160px;
  margin-top: 24px;

  @media (--viewportMedium) {
    /* Layout */
    align-self: center;
    margin-top: 0;
    margin-left: 24px;
  }
}

.bottomContent {
  /* Style */
  border-top: 1px solid var(--matterColorLight);

  /* Padding */
  padding-top: 16px;
  padding-bottom: 16px;
}

.bottomContentWrapper {
  /* Content width */
  composes: content;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.languageSelector {
  /* Layout */
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.languageSelectorIcon {
  margin-right: 8px;
  fill: var(--matterColorLight);
}

.languageSelectorText {
  /* Font */
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    text-decoration: underline;
  }
}

.attractrFooter {
  /* Font */
  composes: fontStyles;
  color: var(--matterColorLight);
}
