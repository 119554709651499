@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Font */
  color: var(--matterColor);

  /* Size */
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 6px;
  padding: 0 16px;

  /* Dimensions */
  height: 36px;
  width: auto;
  min-height: 36px;
  min-width: 70px;

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);
  border-radius: var(--borderRadiusMedium);

  /* Animation */
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--matterColorBright);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
  }
}
