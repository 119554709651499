@import '../../styles/customMediaQueries.css';

:root {
  --formHeight: 56px;
  --submitButtonGap: 8px;
}

.root {
  /* Layout */
  display: flex;
  align-items: center;
  height: var(--formHeight);

  /* Position */
  position: relative;
  text-align: left;
}

.field {
  flex: 1;
  height: var(--formHeight);
}

.fieldInput {
  /* Size */
  height: var(--formHeight);
  padding: 12px 164px 12px 24px;

  /* Style */
  box-shadow: rgba(253, 24, 56, 0.15) 2px 0 14px;
  border: 1px solid var(--matterColorNegative);
  border-bottom-color: var(--matterColorNegative);
  border-radius: 24px;

  /* Effect */
  transition: all var(--transitionStyleButton);

  &:hover,
  &:focus {
    box-shadow: rgba(253, 24, 56, 0.3) 2px 0 14px;
    border-color: var(--matterColorNegative);
    border-bottom-color: var(--matterColorNegative);
  }
}

.submitButton {
  /* Font */
  font-size: 14px;
  line-height: 24px;

  /* Position */
  position: absolute;
  right: calc(var(--submitButtonGap) / 2);

  /* Font */
  text-transform: uppercase;

  /* Size */
  max-width: 140px;
  height: calc(var(--formHeight) - var(--submitButtonGap));
  min-height: calc(var(--formHeight) - var(--submitButtonGap));
  padding: 0;
  border-radius: 24px;
}
