@import '../../styles/customMediaQueries.css';

.slider {
  @media (--viewportMedium) {
    align-items: flex-end;
  }
}

.sliderHeader {
  @media (--viewportMedium) {
    /* Layout */
    display: flex;

    margin-right: 24px;
  }
}

.sliderTitle {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.sliderArrows svg {
  & circle {
    fill: var(--marketplaceColor);
  }
  & path {
    stroke: var(--matterColorLight);
  }
}

.placeholder {
  width: 100%;
  height: 100%;

  /* Style */
  background-color: var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
}
