@import '../../styles/customMediaQueries.css';

.root {
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  height: var(--topbarHeight);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    display: none;
  }
}

.mobileRootDashboard {
  flex-direction: row-reverse;
}

.home {
  display: flex;
  height: 24px;
  width: 24px;
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  margin: 0;
}

.menu,
.searchMenu {
  composes: h5 from global;
  color: var(--matterColor);
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0;
  background-color: transparent;

  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;
}

.searchMenu {
  padding: 12px 24px;
}

.notificationDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 17px;
  right: 17px;
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);
  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;
  position: absolute;
  top: 73px;
}

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);
  right: -355px;
  opacity: 0;
  max-width: 340px;
  margin: 0 15px;
  transition: all ease-out 0.2s;
  transition-delay: 1s;
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--matterColor);
  border-radius: 4px;
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);
}

.notice {
  /* Style */
  background-color: var(--matterColorBright);
  border-bottom: 1px solid var(--matterColorNegative);
}

.noticeContent {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  /* Padding */
  padding-top: 12px;
  padding-bottom: 12px;

  composes: marketplacePageWidth from global;

  @media (--viewportMedium) {
    gap: 24px;

    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media (--viewportLarge) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.noticeContentDashboard {
  max-width: 100%;
}

.noticeText {
  /* Font */
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColor);

  @media (--viewportMedium) {
    line-height: 24px;
  }
}

.closeNoticeButton {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  /* Size */
  height: 24px;
  width: 24px;

  /* Style */
  background-color: var(--matterColorNegative);
  border-radius: 50%;

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    background-color: var(--matterColorBright);

    &:hover {
      background-color: var(--matterColorNegative);
    }
  }
}

.closeNoticeIcon {
  /* Size */
  height: 8px;
  width: 8px;

  /* Style */
  fill: var(--matterColor);
  stroke: none;

  @media (--viewportMedium) {
    height: 10px;
    width: 10px;
  }
}
