.root {
  position: relative;
}

.tabContent {
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  text-transform: capitalize;

  padding: 12px 0;
  @media (--viewportMedium) {
    padding: 12px 6px;
  }

  &:hover,
  &:focus {
    color: var(--marketplaceVariationColor);
  }
}

.selectedTabContent {
  color: var(--marketplaceVariationColor);
  border-color: var(--marketplaceVariationColor);

  &:hover,
  &:focus {
    color: var(--marketplaceVariationColor);
  }
}
