@import '../../styles/customMediaQueries.css';

:root {
  --facebookColor: #3b5998;
  --twitterColor: #00acee;
  --whatsappColor: #25d366;
  --emailColor: #7f7f7f;
}

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 540px;
  }
}

.modalContent {
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;

  margin-bottom: 32px;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.shareButtons {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  /* Style */
  background-color: var(--matterColorLight);
}

.shareButton {
  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  /* Layout */
  display: flex;
  align-items: center;
  padding: 0;

  /* Size */
  height: 50px;

  /* Effect */
  cursor: pointer;

  @media (--viewportMedium) {
    &:hover {
      border-color: var(--matterColor);
    }
  }
}

.shareIcon {
  height: 24px;
  width: 24px;

  margin-left: 16px;
  margin-right: 8px;
}
