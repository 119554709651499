@import '../../styles/customMediaQueries.css';

.root {
  & label,
  & legend {
    color: var(--matterColor);
  }
}

.newPasswordSection {
  margin-top: 0;
  margin-bottom: 0;
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding: 0;
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 24px;
}

.bottomWrapper {
  margin-top: 46px;
}

.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.helperLink {
  composes: a from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.submitButton {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;

  /* Size */
  min-height: 56px;
}
