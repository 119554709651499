@import '../../../styles/customMediaQueries.css';

.root {
  /* Size */
  width: fit-content;

  @media (--viewportMedium) {
    height: 44px;
    width: 100%;

    margin-bottom: 24px;
  }
}

.menuLabel {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 16px 24px;

  /* Font */
  font-size: 14px;
  line-height: 18px;

  @media (--viewportMedium) {
    /* Layout */
    gap: 12px;
    padding: 0 12px;

    /* Style */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: var(--borderRadiusMedium);

    &:hover,
    &:focus {
      background-color: var(--matterColorLight);
      border-color: var(--matterColor);
    }
  }
}

.menuLabelChevron {
  height: 6px;

  @media (--viewportMedium) {
    margin-left: auto;
  }
}

.menuContent {
  top: calc(56px - 6px); /* 44px is the height of the menuLabel */
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: var(--borderRadiusMedium);
  box-shadow: var(--boxShadowLight);

  @media (--viewportMedium) {
    top: calc(44px + 6px); /* 44px is the height of the menuLabel */
  }
}

.menuLink {
  /* Font */
  composes: h4 from global;
  color: var(--matterColor);
  text-align: left;

  /* Position */
  position: relative;

  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 12px;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 6px 24px;

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceVariationColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.menuLinkSelected {
  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColor);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColor);
    }
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 0;
  transition: width var(--transitionStyleButton);
}

.organizationProfileName {
  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.organizationProfileImage {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.organizationProfileNoImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  /* Style */
  composes: organizationProfileImage;
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceVariationColor) 0%,
    var(--marketplaceVariationColorDark) 100%
  );
}

.organizationProfileNoImageInitials {
  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
}

.spinnerIcon {
  height: 24px;
  width: 24px;

  stroke: var(--marketplaceVariationColor);
}
