@import '../../styles/customMediaQueries.css';

.root {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
}

.primaryButtonRoot {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.secondaryButtonRoot {
  composes: button buttonFont buttonText buttonBordersSecondary buttonColorsSecondary from global;
}

.inlineTextButtonRoot {
  composes: a from global;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
}

.primaryButton.inProgress:disabled {
  background-color: var(--marketplaceColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--marketplaceColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 20px;
  height: 20px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  composes: button buttonFont buttonText from global;

  min-height: 48px;
  padding: 12px 0;

  background-color: var(--matterColorLight);
  color: var(--matterColorDark);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  border: 1px solid #d2d2d2;
  border-radius: 4px;

  /* We need to add this to position the icon inside button */
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorBright);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
  }
}

.socialButton {
}

.editWizardButtonRoot {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Font */
  color: var(--matterColorLight);

  /* Size */
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 16px;

  /* Dimensions */
  height: 36px;
  width: auto;
  min-height: 36px;
  min-width: 70px;

  /* Style */
  composes: buttonColorsVariation from global;
  border: none;
  border-radius: var(--borderRadiusMedium);

  /* Animation */
  cursor: pointer;
}

.editWizardButton {
}

.editWizardButton.inProgress:disabled {
  background-color: var(--marketplaceVariationColor);
}

.editWizardButton .checkmark {
  width: 16px;
  height: 16px;
}
