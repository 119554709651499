.uploadContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.uploadTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.uploadButton {
  /* Layout */
  display: flex;
  align-items: center;

  /* Size */
  width: auto;
  padding: 10px 18px;

  &:disabled {
    background-color: var(--matterColorLight);
    color: var(--matterColor);
  }
}

.uploadIcon {
  margin-right: 6px;
}
