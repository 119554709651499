@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
}

.error {
  color: var(--failColor);

  margin-top: 12px;
  margin-bottom: 0;
}

.commentWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.comment {
  flex-shrink: 0;
  flex: 1;
}

.commentTextarea {
  min-height: auto;
}

.formButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;

  margin-top: 12px;
}

.cancelButton {
  /* Size */
  height: 36px;
  width: fit-content;
  padding: 0 16px;
  border-radius: calc(var(--borderRadiusMedium) * 2 + 2px);

  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColor);
    color: var(--matterColorLight);
    text-decoration: none;

    &:disabled {
      background-color: var(--matterColorNegative);
    }
  }
}

.submitButton {
  /* Size */
  height: 36px;
  width: fit-content;
  padding: 0 16px;
  border-radius: calc(var(--borderRadiusMedium) * 2 + 2px);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background-color: var(--marketplaceColor);

  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColorLight);
    text-decoration: none;

    &:disabled {
      background-color: var(--matterColorNegative);
    }
  }
}
