@import '../../styles/customMediaQueries.css';

.root {
}

.heading,
.submitButton {
  padding-left: 24px;
  padding-right: 24px;

  @media (--viewportMedium) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.heading {
  margin-top: 48px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

/* Exception start and end inputs */
.section {
  display: flex;
  flex-direction: column;

  /* Margin */
  margin-top: 24px;
  margin-bottom: 48px;

  /* Padding */
  padding-left: 24px;
  padding-right: 24px;

  @media (--viewportMedium) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  width: 100%;
  padding: 0;
  margin-top: 24px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
  }
}

.timezoneAndDate {
  grid-template-columns: 1fr;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;

  width: 100%;
  & > div {
    width: 100%;
  }
}

.fieldDisabled {
  background: none;
  color: var(--matterColorNegative);
}

.fieldDateInput,
.fieldRepeatingDateInput {
  flex-grow: 1;

  & :global(.SingleDatePickerInput) {
    width: 100%;
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    @media (max-width: 768px) {
      /* Position */
      top: 48px !important;

      /* Size */
      min-height: 100%;
      width: 100%;

      /* Style */
      border-top: 0;
      border-radius: var(--borderRadius);
    }
  }
}

.fieldSelect {
  flex-grow: 1;
  margin-left: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 12px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%230e0f0c" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%230e0f0c"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      bottom: 14px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }
}

.select {
  position: relative;
  padding-left: 34px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%230e0f0c" fill="%230e0f0c" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;

  @media (--viewportMedium) {
    padding-left: 38px;
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  padding-bottom: 96px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.exceptionButton {
  composes: buttonColorsVariation from global;
}

.bottomSection {
  padding-top: 24px;
  margin-top: 24px;

  /* Layout */
  border-top: 1px solid var(--matterColorNegative);
}

.bottomField {
  margin-top: 24px;
}

.repeatDates {
}

.repeatDate {
  /* Layout */
  display: flex;
  align-items: center;

  /* Position */
  position: relative;

  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.repeatingButtons {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  position: absolute;
  right: 1px;

  /* Fill */
  height: 100%;
}

.addRepeatingDateButton,
.removeRepeatingDateButton {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: calc(100% - 2px);
  width: 40px;

  /* Style */
  background-color: var(--matterColorBright);
  border-left: 1px solid var(--matterColorNegative);
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:disabled {
    /* Make button disabled */
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    background-color: var(--matterColorNegative);
  }

  @media (--viewportMedium) {
    /* Size */
    width: 42px;
  }
}

.removeRepeatingDateButton {
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

.addRepeatingDateIcon,
.removeRepeatingDateIcon {
  fill: var(--matterColor);
}

.removeRepeatingDateIcon {
  stroke: var(--matterColor);

  height: 8.5px;
  width: 8.5px;
}
