.root {
  /* Layout */
  padding: 24px;

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.description {
  margin: 12px 0;
}

.info {
  margin-top: 2px;
}

.separator {
  margin: 0 5px;
}

.perksList {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin: 12px 0 0;
  padding: 0;
}

.perksListItem {
  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.checkmarkIcon {
  fill: var(--matterColor);

  /* Layout */
  height: 14px;
  width: 14px;
  flex-shrink: 0;

  margin-top: 6px;
}
