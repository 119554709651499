@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding: 80px 24px 24px;

  @media (--viewportMedium) {
    flex-basis: 500px;
    padding: 80px 32px 32px;
  }
}

.modalContent {
  flex-grow: 1;

  width: 100%;
  margin: 0 auto;
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 24px;
}

.modalHeaderContent {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (--viewportMedium) {
    gap: 0px;
  }
}

.modalTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.modalSubTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.rootForImage {
  max-width: 170px;
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    max-width: 200px;
  }
}

.donations {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.donation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  &:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.donationName {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;
}

.donateButton {
  /* Size */
  height: 42px;
  max-width: 100px;

  padding: 0;

  /* Style */
  background-color: var(--matterColor);

  &:hover,
  &:focus {
    background-color: var(--matterColor);
    opacity: 0.8;

    &:disabled {
      background-color: var(--matterColorNegative);
      opacity: 1;
    }
  }
}

.noDonationsText {
  margin-top: 0;
  margin-bottom: 0;
}

.modalFooter {
  display: flex;
  flex-direction: column;

  margin-top: 48px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.contactButton {
  width: 100%;
}

.contactNotice {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  line-height: 22px;
  color: var(--matterColorAnti);
  text-align: center;

  margin-top: 0;
  margin-bottom: 12px;
}

.loadMoreButtonContainer {
  /* Layout */
  display: flex;
  align-items: center;
  margin-top: 24px;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--matterColorNegative);
  }
}

.loadMoreButton {
  /* Font */
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);
  border-radius: 20px;

  /* Size */
  width: fit-content;
  padding: 12px 24px;
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    box-shadow: none;
  }
}
