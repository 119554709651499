.root {
  /* Input */
  & :global(.customSelect__Input) {
    margin: 0;
    padding: 0;
    height: 24px;

    & input {
      height: 24px;
    }
  }

  /* Control */
  & :global(.customSelect__control) {
    border-color: var(--matterColorNegative);
    box-shadow: none;
    &:hover {
      border-color: var(--matterColor);

      & :global(.css-tlfecz-indicatorContainer) {
        color: var(--matterColor);
      }
    }
  }

  /* Control */
  & :global(.customSelect__control) {
    padding: 0;
  }

  /* Value container */
  & :global(.customSelect__value-container) {
    padding: 4px 12px 4px 10px;
  }

  & :global(.customSelect__Input) {
    margin: 0 5px 9px 0;
  }

  & :global(.customSelect__placeholder) {
    /* Font */
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    color: var(--matterColorAnti);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* No margin */
    margin: 0;

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  & :global(.customSelect__indicator) {
    color: var(--matterColorAnti);

    &:hover {
      color: var(--matterColor);
    }
  }

  /* Indicators */
  & :global(.customSelect__indicator-separator) {
    background-color: var(--matterColorAnti);
  }

  /* Multi value */
  & :global(.customSelect__multi-value) {
    border-radius: var(--borderRadius);

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Value tag */
  & :global(.customSelect__multi-value__label) {
    color: var(--matterColor);
    padding-top: 0;
    padding-bottom: 0;
  }

  & :global(.customSelect__multi-value__remove) {
    &:hover {
      background-color: var(--failColor);
      color: var(--failColorLight);
    }
  }

  /* Menu */
  & :global(.customSelect__menu) {
    box-shadow: var(--boxShadowLight);
    border: 1px solid var(--matterColorNegative);
  }

  /* Menu list */
  & :global(.customSelect__menu-list) {
    padding: 0;
  }

  /* Option */
  & :global(.customSelect__option) {
    font-size: 14px;
  }

  & :global(.customSelect__option--is-focused) {
    background-color: var(--marketplaceVariationColorLight);
  }
  & :global(.customSelect__option--is-selected) {
    background-color: var(--marketplaceVariationColor);

    &:hover {
      background-color: var(--marketplaceVariationColor);
    }
  }
}

.selectError {
  & :global(.customSelect__control),
  & :global(.customSelect__control) {
    border-color: var(--failColor);
  }
}
