@import '../../styles/customMediaQueries.css';

.root {
}

.comments {
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;
}

.comments {
  display: flex;
}

.commentItem {
  padding-bottom: 16px;

  @media (--viewportMedium) {
    .controlMenu {
      display: none;
    }

    &:hover {
      .controlMenu {
        display: block;
      }
    }
  }

  @media (--viewportLarge) {
    padding-bottom: 24px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.comment {
  /* Layout */
  display: flex;
  gap: 12px;

  position: relative;

  @media (--viewportMedium) {
    gap: 18px;
  }

  @media (--viewportLarge) {
    flex-shrink: 1;
    flex-grow: 0;
  }
}

.featuredcomment {
  box-shadow: var(--boxShadowBottomForm);
}

.avatar {
  flex-shrink: 0;
}

.commentContent {
  margin-top: -2px;
}

.commentBio {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;

  padding-right: 32px;

  & p {
    display: inline;
  }
}

.commentInfo {
  display: flex;
  flex-direction: column;
}

.commentRating {
  display: flex;
  align-items: center;
}

.commentRatingText {
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
}

.commentRatingCount {
  color: var(--matterColor);
}

.commentRatingStar {
  height: 18px;
  width: 18px;

  margin-right: 6px;
}

.commentInfoDetails {
  composes: h5 from global;
  color: var(--matterColorAnti);

  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;

  margin-top: 0;
  margin-bottom: 0;
}

.commentShowMore {
  /* Font */
  composes: a from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  text-decoration: underline;
  text-transform: lowercase;

  & svg {
    display: none;
  }
}

.commentThumbButtons {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 12px;
}

.commentThumbButtonContainer {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;
}

.commentThumbButton {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  border-radius: calc(var(--borderRadiusMedium) * 2);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorLight);
  }
}

.commentThumbCount {
  color: var(--matterColorAnti);
  line-height: 1;
}

.commentThumbIcon {
  flex-shrink: 0;
}

.repliesButton {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Size */
  height: 36px;
  padding: 0 16px;
  border-radius: calc(var(--borderRadiusMedium) * 2 + 2px);

  /* Font */
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);

  margin-top: 8px;

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColorLight);
    text-decoration: none;
  }
}

.repliesButtonIcon {
  /* Color */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  /* Size */
  height: 8px;
  width: auto;
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.userDisplayName {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.commentDate {
  font-weight: var(--fontWeightRegular);
}

.featuredcommentTextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featuredcommentText {
  /* Font */
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);

  /* Style */
  padding: 0 8px;
  background: var(--marketplaceColor);
  border-radius: var(--borderRadius);
}
