@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

@keyframes navWrapperAnimation {
  from {
    transform: translateX(calc(var(--TabNav_editListingLinkWidth) * -1));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes saveAndExitLinkAnimation {
  from {
    opacity: 0;
    transform: translateX(-24px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.navWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;

  @media (--viewportLarge) {
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;

    /* Animation */
    animation-name: navWrapperAnimation;
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    font-size: 16px;
    font-weight: var(--fontWeightRegular);

    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;

    padding: 8px 12px;
    width: var(--TabNav_editListingLinkWidth);

    /* Style */
    background-image: none;
    background-position: unset;
    background-color: transparent;
    border-radius: 21px;
    border: 2px solid transparent;
    outline: none;

    margin-top: 0;
    margin-bottom: 10px;

    /* Effects */
    transition: none;

    &:hover {
      background-color: rgb(235, 235, 235);
      background-image: none;
      background-position: unset;
    }
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);

  @media (--viewportLarge) {
    color: var(--marketplaceVariationColor);
    font-weight: var(--fontWeightSemiBold);

    /* Style */
    border: 2px solid var(--marketplaceVariationColor);
    border-radius: 21px;

    &:hover {
      color: var(--marketplaceVariationColor);
      background-image: none;
      background-position: unset;
      background-color: rgb(235, 235, 235);
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

.title {
  margin-top: 0;
  margin-bottom: 24px;

  padding-left: 12px;

  @media (--viewportLarge) {
    max-width: 190px;
  }
}

.lockIconWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  border-radius: 50%;
  border: 1px solid var(--matterColor);
  color: var(--matterColor);

  /* Size */
  width: 16px;
  height: 16px;
}

.logoWrapper {
  display: none;
  @media (--viewportLarge) {
    display: flex;
  }
}

.logoWrapper {
  /* Layout */
  align-items: center;
  justify-content: space-between;
  width: 100%;

  /* Margin & Padding */
  padding-left: 12px;
  margin-bottom: 28px;
}

.saveAndExitLink {
  /* Font */
  composes: h5 from global;
  text-decoration: underline;
  color: var(--matterColorSemiLight);

  /* Position */
  position: relative;
  top: 2px;

  margin-top: 0;
  margin-bottom: 0;

  /* Effect */
  transition: var(--transitionStyleButton);

  /* Animation */
  animation-name: saveAndExitLinkAnimation;
  animation-duration: 1000ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  &:hover {
    color: var(--matterColor);
  }
}

.logoContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    align-items: center;
  }
}

.logo {
  height: 32px;
  width: auto;
}

.arrowContainer {
  padding-left: 24px;
  padding-right: 12px;
  margin-left: 0;

  & .link {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  /* Target next div */
  & + div {
    margin-left: 12px;

    @media (--viewportLarge) {
      margin-left: 0;
    }
  }

  @media (--viewportLarge) {
    display: none;
  }
}
