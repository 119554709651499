@import '../../styles/customMediaQueries.css';

:root {
  --modalImageWidth: 200px;
}

.root {
  composes: marketplaceModalBaseStyles from global;
  padding: 72px 24px 24px;

  @media (--viewportMedium) {
    flex-basis: 650px;
    padding: 72px 48px 48px;
  }
}

.upgradeMembershipModal {
  @media (--viewportMedium) {
    flex-basis: 700px;
  }
}

.freeMembershipModal {
  @media (--viewportMedium) {
    flex-basis: 550px;
  }
}

.modalContent {
  flex-grow: 1;

  width: 100%;
  margin: 0 auto;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalHeaderUpgrade {
  display: flex;
  align-items: center;
  gap: 24px;
}

.modalHeaderContent {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (--viewportMedium) {
    gap: 0px;
  }
}

.modalFooter {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.modalTitle {
  margin-top: 0;
  margin-bottom: 0;

  /* Font */
  text-align: center;
}

.upgradeModalTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.freeModalTitle {
  margin-top: 0;
  margin-bottom: 24px;

  /* Font */
  text-align: center;
}

.modalSubTitle {
  margin-top: 12px;
  margin-bottom: 0;

  text-align: center;
}

.modalSubTitleWithMargin {
  composes: modalSubTitle;
  margin-top: 24px;
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.rootForImage {
  display: none;

  @media (--viewportMedium) {
    display: block;
    border-radius: var(--borderRadiusMedium);
    max-width: var(--modalImageWidth);
  }
}

.memberships {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  margin-top: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.upgradeMembershipContainer {
}

.upgradeMembershipTitle {
  margin-top: 32px;
  margin-bottom: 4px;
}

.upgradeMembershipText {
  margin-top: 0;
  margin-bottom: -12px;
}

.noMembershipsText {
  margin-top: 24px;
  margin-bottom: 0;
}

.membership {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 24px;

  /* Position */
  position: relative;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
}

.membershipName {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  display: flex;
  align-items: center;

  margin-top: 0;
  margin-bottom: 0;
}

.membershipDescription {
  /* Font */
  font-size: 14px;
  line-height: 24px;

  margin-top: 6px;
  margin-bottom: 0;
}

.membershipButton {
  /* Size */
  height: 42px;

  /* Style */
  background-color: var(--matterColor);

  /* Layout */
  margin-top: auto;
  padding: 0;
  max-width: calc(50% + 24px);

  &:hover {
    background-color: var(--matterColor);
    opacity: 0.8;

    &:disabled {
      background-color: var(--matterColorNegative);
      opacity: 1;
    }
  }
}

.membershipPerks {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.membershipPerksName {
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 0;
  margin-bottom: 0;
}

.membershipPerksList {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin: 12px 0 24px;
  padding: 0;
}

.membershipPerksListItem {
  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 12px;

  /* Font */
  font-size: 14px;
  line-height: 24px;
}

.membershipActiveIcon {
  /* Layout */
  position: absolute;
  right: 24px;

  & path {
    fill: var(--attentionColor);
  }
}

.checkmarkIcon {
  fill: var(--matterColor);

  /* Layout */
  height: 14px;
  width: 14px;
  flex-shrink: 0;

  margin-top: 5px;
}

.separator {
  margin: 0 5px;
}

.contactButton {
  width: 100%;
}

.submitButton {
  width: 100%;
  margin-top: 48px;
}

.contactNotice {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  line-height: 22px;
  color: var(--matterColorAnti);
  text-align: center;

  margin-top: 0;
  margin-bottom: 12px;
}

.skipButton {
  color: var(--matterColor);
}
