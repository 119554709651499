@import '../../styles/customMediaQueries.css';

.root {
}

.content {
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* Font */
  text-align: center;

  /* Style */
  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
  padding: 48px 24px;
}

.title {
  /* Font */
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 24px;
}

.heading {
  margin-top: 28px;
  margin-bottom: 0;
}

.subHeading {
  /* Font */
  text-align: center;

  margin-top: 12px;
  margin-bottom: 0;

  /* Size */
  max-width: 450px;
}

.icon {
  fill: none;
}

.button {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Reset */
  padding: 12px 24px;
  width: fit-content;

  margin-top: 32px;

  &:hover {
    opacity: 0.8;
  }
}
