@import '../../styles/customMediaQueries.css';

.title {
  /* Font */
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 24px;
}

.videos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px 16px;
  }
}

.videoPlayerContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.author {
  /* Font */
  font-size: 14px;
  line-height: 24px;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  margin-top: 12px;
}

.authorLink {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.views {
  /* Font */
  font-size: 14px;
  line-height: 24px;
}

.loadMoreButtonContainer {
  /* Layout */
  display: flex;
  align-items: center;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--matterColorNegative);
  }
}

.loadMoreButton {
  /* Font */
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);
  border-radius: 20px;

  /* Size */
  width: fit-content;
  padding: 12px 24px;
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    box-shadow: none;
  }
}
