.root {
}

.input {
  border-color: var(--matterColorNegative);
}

.inputSuccess {
  border-color: var(--matterColorNegative);
}

.inputError {
  border-color: var(--failColor);
}
