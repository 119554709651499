@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0;
  color: var(--matterColorLight);
  text-align: center;

  background-color: var(--failColor);

  /* Layout of a red dot */
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-left: 5px;

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    top: -10px;
    position: relative;
  }
}

.spinner {
  stroke: var(--matterColorLight);

  /* Size */
  height: 10px;
  width: 10px;
}
