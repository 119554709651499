@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  composes: h4 from global;
  border-width: 0;

  margin: 0;

  /* Style */
  border-width: 2px;

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 6px;

  margin-top: 12px;
}

.quickReplyButton {
}

.submitButton,
.quickReplyButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  /* Font */
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  margin: 0;
  width: auto;
}

.sendIcon {
  margin-right: 6px;
}
