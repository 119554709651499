@import '../../styles/customMediaQueries.css';

.root {
  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(var(--zIndexTopbar) + 1);

  /* Size */
  height: var(--topbarHeight);
  width: 100%;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowBorderLine);

  /* Effect */
  opacity: 0;
  visibility: hidden;

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }
}

.rootVisible {
  opacity: 1;
  visibility: visible;
}

.content,
.list,
.listItem,
.listItemButton {
  /* Layout */
  display: flex;
  align-items: center;

  /* Size */
  height: 100%;
}

.content {
  @media (--viewportMedium) {
    max-width: var(--marketplacePageWidth);
    margin: 0 auto;
    padding: 0 24px;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  /* Scroll */
  overflow-y: auto;
}

.listItem {
  height: 100%;
  margin-right: 24px;

  &:first-child {
    margin-left: 24px;
  }
  &:last-child {
    margin-right: 24px;
  }

  @media (--viewportMedium) {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.listItemButton {
  /* Font */
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  white-space: nowrap;

  /* Position */
  position: relative;

  /* Remove underline */
  text-decoration: none !important;

  /* Fill height */
  height: 100%;
}

.listItemButton:after {
  content: '';
  /* Position */
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 4px;
  background-color: var(--matterColor);
  width: 100%;

  /* Effect */
  opacity: 0;
  transition: var(--transitionStyleButton);
}

.listItemButton:hover:after {
  opacity: 1;
}
