@import '../../styles/customMediaQueries.css';

.root {
}

.header {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.experiences {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}
