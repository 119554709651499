@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 6px;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.content {
  position: relative;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadiusMedium);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 12px 6px 6px 6px;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.subscriptions {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);
}

.subscriptionsCount {
  font-weight: var(--fontWeightMedium);
}

.author {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);

  margin-top: 6px;
}

.authorName {
  font-weight: var(--fontWeightMedium);
}

.details {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.detailsItem {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);

  &:not(:first-child) {
    &:before {
      content: '/';
      margin: 0 4px;
    }
  }
}

.availabilityExceptionsHeading {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  margin-top: 12px;
  margin-bottom: 6px;
}

.availabilityExceptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
}

.availabilityException {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  /* Font */
  font-size: 10px;
  line-height: 18px;
  color: var(--matterColor);
  white-space: nowrap;

  /* Style */
  background-color: var(--matterColorBright);
  border-radius: var(--borderRadiusMedium);

  @media (--viewportLarge) {
    font-size: 12px;
    line-height: 20px;
  }
}

.availabilityExceptionIcon {
  /* Size */
  height: 12px;
  width: 12px;

  margin-right: 4px;

  /* Style */
  & path {
    fill: var(--attentionColor);
  }
}

.strong {
  font-weight: var(--fontWeightMedium);
}
