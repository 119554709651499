@import '../../styles/customMediaQueries.css';

.root {
}

.header {
  display: flex;
  align-items: center;
}

.info {
  flex: 1;
}

.headingRow {
  display: flex;
  flex-direction: column;
}

.heading {
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;
}

.hostSinceAndFollowers {
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
  }
}

.avatar {
  flex-shrink: 0;
  margin-right: 16px;
}

.bio {
  composes: h4 from global;
  white-space: pre-line; /* Preserve newlines, but collapse other whitespace */

  margin-top: 24px;
  margin-bottom: 0;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.separator {
  margin: 0 8px;
}

.contact {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  max-width: 100%;
  margin-top: 24px;

  /* Style */
  border-radius: var(--borderRadiusMedium);
  border-color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorBright);
  }

  @media (--viewportMedium) {
    max-width: 170px;
  }
}

.profileLink {
  color: var(--matterColor);
  text-decoration: underline;
}
