@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

.header {
  display: flex;
  align-items: center;
  gap: 24px;
}

.image {
  display: none;

  @media (--viewportMedium) {
    display: flex;

    /* Size */
    height: 100px;
    width: 120px;

    /* Style */
    object-fit: cover;
    border-radius: var(--borderRadiusMedium);
  }
}

.title {
  composes: marketplaceModalTitleStyles from global;
}

.date {
  /* Layout */
  display: flex;
  gap: 6px;

  /* Font */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;

  margin-top: 4px;
}

.dateDivider {
  margin: 0 6px;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.infoItem {
  /* Layout */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  /* Style */
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 32px;
  }

  & a {
    color: var(--marketplaceVariationColor);
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.infoTitle {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 10px;

  /* Font */
  font-weight: var(--fontWeightSemiBold);
  white-space: nowrap;
}

.infoValue {
  /* Limit to only one line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.iconType {
  height: 18px;
  width: 18px;
  stroke: var(--matterColor);

  & path {
    stroke-width: 1.4;
  }
}
