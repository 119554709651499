@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--marketplaceFooterColor);
  border-bottom: 1px solid var(--matterColorLight);

  padding: 48px 0;
}

.sliderHeader {
  padding-left: 24px;
  padding-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
    padding-right: 0;
  }
}

.sliderTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 0;
}

.listings {
  width: 100%;
  max-width: var(--marketplacePageWidth);
  margin: 0 auto;

  @media (--viewportLarge) {
    padding: 0 24px;
  }
}

.listingCard {
}

.listingAddressAndPrice {
  display: none;
}
