@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.modalButtons {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  margin-top: 24px;
}

.submitButtonVariationColor {
  composes: buttonColorsVariation from global;
}
