@import '../../styles/customMediaQueries.css';

.root {
}

.header {
  display: flex;
  align-items: center;
}

.info {
  flex: 1;
}

.headingRow {
  display: flex;
  flex-direction: column;
}

.heading {
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;
}

.bio {
  composes: h4 from global;
  white-space: pre-line; /* Preserve newlines, but collapse other whitespace */

  margin-top: 12px;
  margin-bottom: 0;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.separator {
  margin: 0 8px;
}

.profileImage {
  /* Layout */
  margin-right: 16px;

  /* Size */
  height: 48px;
  width: 48px;

  /* Style */
  background-color: var(--matterColorNegative);
  box-shadow: var(--boxShadowLight);
  border: 2px solid var(--matterColorLight);
  border-radius: 50%;
  object-fit: cover;

  @media (--viewportMedium) {
    height: 60px;
    width: 60px;
  }
}

.profileNoImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  /* Style */
  composes: profileImage;
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
}

.profileNoImageInitials {
  /* Font */
  font-size: 26px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);

  /* Reset */
  margin-top: 0;
  margin-bottom: 0;
}
