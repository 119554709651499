@import '../../styles/customMediaQueries.css';

.root {
}

.expandableText {
  margin-top: 0;
  margin-bottom: 0;

  word-break: break-word;
}

.showMore {
  /* Layout */
  display: flex;
  align-items: center;

  /* Borders */
  border: none;

  /* Font */
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);

  /* Text size should be inherited */
  text-decoration: underline;
  text-underline-position: auto;

  /* Margin & Padding */
  margin: 6px 0 0;
  padding: 0;

  /* Effect */
  transition: all var(--transitionStyleButton);

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.arrowIcon {
  margin-top: 1px;
  margin-left: 8px;

  /* Size */
  width: 8px;
  height: 12px;
}
