@import '../../styles/customMediaQueries.css';

.root {
}

.topbar {
  border-bottom: 1px solid var(--matterColorNegative);
  box-shadow: none;
}

.topbarContent {
  max-width: 100%;
  padding: 0 48px 0 0;

  & svg {
    fill: var(--matterColor);
  }
}

.layoutWrapperMain {
  background-color: var(--matterColorLight);
}

.pageContent {
  padding: 24px;
  flex: 1;

  @media (--viewportMedium) {
    padding: 48px;
  }
}

.pageTitle {
  composes: dashboardTitle from global;

  margin-top: 0;
  margin-bottom: 24px;
}

.redirectBanner {
  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);

  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Size */
  padding: 16px 24px;
  max-width: 800px;
  margin-bottom: 32px;

  /* Style */
  background-color: var(--marketplaceVariationColor);
  border-radius: var(--borderRadius);

  /* Effect */
  transition: var(--transitionStyleButton);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.organizationSettingsNav {
  margin: 0 -24px;

  &:before,
  &:after {
    content: '';
    display: block;
    background-color: transparent;
    border: 12px solid transparent;
  }

  @media (--viewportMedium) {
    margin: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 12px;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.inviteModalContainer {
  composes: marketplaceModalBaseStyles from global;

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

.inviteModalContent {
  flex-grow: 1;
}
