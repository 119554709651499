@import '../../styles/customMediaQueries.css';

:root {
  --contentMaxWidth: 100%;
  --contentMaxWidthMobile: 100vw;
}

/* Slider */
.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sliderHeader {
  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
}

.sliderTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

/* Arrows */
.sliderArrows {
  align-items: center;
  align-self: flex-end;
  gap: 8px;
}

.sliderArrows {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.sliderArrow {
  /* Effect */
  cursor: pointer;
  transition: all var(--transitionStyleButton);

  &:hover {
    transform: scale(1.04);
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.sliderArrowIcon {
  position: relative;
}
.sliderArrowIcon path {
  fill: none;
}

/* Slider items */
.sliderItems {
  /* Layout */
  display: flex;
  flex-wrap: nowrap;
  width: var(--contentMaxWidthMobile);

  /* Scroll */
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Space */
  &:after {
    content: '';
    display: block;
    background-color: transparent;
    border: 12px solid transparent;
  }

  @media (--viewportMedium) {
    scroll-snap-type: x mandatory;
    width: var(--contentMaxWidth);

    &:after {
      display: none;
    }
  }
}

/* Slider item */
.sliderItem {
  /* Layout */
  flex: 0 0 auto;
  width: var(--contentMaxWidthMobile);
  margin-right: 16px;

  /* Scroll */
  scroll-margin-left: 24px;

  @media (--viewportMedium) {
    /* Layout */
    width: var(--contentMaxWidth);
    margin-right: 24px;

    /* Scroll */
    scroll-snap-align: start;
    scroll-margin-right: 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    margin-right: 16px;

    /* Scroll */
    scroll-margin-left: 0;
  }
}

.sliderItem:first-child {
  margin-left: 24px;

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.sliderItem:last-child {
  margin-right: 0;

  @media (--viewportMedium) {
    margin-right: 24px;
  }

  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.sliderSpacing {
  @media (--viewportLarge) {
    & .sliderItem {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    &:before,
    &:after {
      content: '';
      display: block;
      background-color: transparent;
      border: 12px solid transparent;
    }
  }
}

.scrollSnapStart {
  scroll-snap-align: start;
}

.scrollSnapCenter {
  scroll-snap-align: center;
}
