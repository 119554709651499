.root {
  display: flex;
  flex-direction: column;
}

.users {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
  background: rgba(231, 231, 231, 0.2);
}

.noResultsText {
  margin-top: 0;
  margin-bottom: 0;

  /* Padding */
  padding: 18px;
}

.errorText {
  color: var(--failColor);

  margin-top: 0;
  margin-bottom: 0;

  /* Padding */
  padding: 18px;
}

.loadingIcon {
  stroke: var(--marketplaceVariationColor);

  /* Padding */
  margin: 18px;
}

.inviteButton {
  /* Font */
  font-size: 14px;
  line-height: 18px;

  /* Size */
  min-height: 42px;
  height: 42px;
  width: fit-content;

  /* Margin & Padding */
  margin-bottom: 12px;
  padding: 0 18px;
}

.user {
  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 24px;

  /* Padding */
  padding: 12px 18px;

  /* Border */
  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 18px;
}

.userAvatar {
  height: 48px;
  width: 48px;
}

.userText {
  display: flex;
  flex-direction: column;
}

.userDisplayName {
  font-weight: var(--fontWeightMedium);
}

.userAttributes {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);

  /* Limit to 1 line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  /* Margin */
  margin-top: 0;
  margin-bottom: 0;
}

.dotDivider {
  margin: 0 5px;
}

.userButtons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userButton {
  composes: inviteButton;
  max-width: 190px;

  /* Margin & Padding */
  margin: 0;
  padding: 0 18px;
}

.resendButton {
  background-color: var(--matterColorNegative);
}

.showMoreButton {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  /* Padding */
  padding: 12px 18px;

  &:hover {
    /* Text decoration */
    text-decoration: none;

    /* Style */
    background-color: var(--matterColorAntiLight);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.revokeError,
.removeError {
  /* Font */
  font-size: 14px;
  line-height: 18px;
  color: var(--failColor);

  /* Margin */
  margin-top: 0;
  margin-bottom: 0;
}
