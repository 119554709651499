.root {
}

.marketplaceStroke {
  stroke: var(--matterColor);
}

.successFill {
  fill: var(--successColor);
}

.checkStroke {
  stroke: var(--matterColorLight);
}
