@import '../../styles/customMediaQueries.css';

.desktopPanel {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.desktopHeader {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  margin-top: 0;
  margin-bottom: 24px;
  padding: 0;
}

.desktopContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.priceText {
  margin-top: 0;
  margin-bottom: 0;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    /* Font */
    font-size: 32px;
    line-height: 38px;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.dates,
.tour {
  margin-top: 0;
  margin-bottom: 0;
}

.tourName {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  &:hover {
    text-decoration: none;
    cursor: auto;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 16px 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  min-height: 80px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 5.5px;
    margin-bottom: 5.5px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
}

.listingInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-top: 0;
  margin-bottom: 0;
}

.listingInfoItem {
  display: flex;
  align-items: center;
  gap: 12px;
}

.bookButton,
.ownBookButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  border-radius: var(--borderRadius);

  /* Size */
  max-width: 135px;
  padding: 12px 18px;

  /* Style */
  border-radius: var(--borderRadiusMedium);
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  @media (--viewportLarge) {
    /* Top margin */
    margin-top: 24px;
    max-width: 100%;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 0 24px;
  text-align: center;
}

.calendarIcon {
  /* Position */
  position: absolute;
  top: 28.5px;
  right: 32px;

  /* Size */
  height: 32px;
  width: 32px;
}
