.root {
  justify-content: flex-start;
}

.password {
  composes: marketplaceModalPasswordMargins from global;

  margin-top: 24px;
  margin-bottom: 0;
}

.submitButton {
  margin-top: 48px;
}
