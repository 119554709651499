@import '../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.dashboardRoot .currentPage {
  color: var(--matterColorDark) !important;
}

.dashboardRoot .createNewListingLink {
  background-color: var(--marketplaceVariationColor);
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 180px; /* For footer and scrolling on mobile safari */
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
}

.avatar {
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  display: inline;
  border: none;
  text-decoration: none;
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorAnti);
  margin-top: 4px;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 12px;
  text-decoration: none;

  /* Layout */
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.firstNavigationLink {
  margin-top: 48px;
}

.navigationMainLink {
  font-weight: var(--fontWeightMedium);
}

.currentPage {
  color: var(--matterColorDark);
  border-left: 5px solid var(--matterColorDark);
  margin-left: -24px;
  padding-left: 19px;
}

.createNewListingLink {
  composes: button buttonFont buttonText marketplaceButtonBorders buttonColors from global;
  border-radius: var(--borderRadiusMedium);
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink,
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.notificationBadge {
  /* Font */
  font-size: 12px;

  /* Size */
  height: 20px;
  width: 20px;
}
