@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 96px auto 96px auto;
    padding: 0 24px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

/* PanelHeadings subcomponent */
.panelHeading {
  padding: 24px 24px 0;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.panelHeadingTitle {
  margin-top: 0;
  margin-bottom: 12px;
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 0;
  padding: 0 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.detailsContainerMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.detailsContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    /* Position */
    position: sticky;
    top: calc(var(--topbarHeightDesktop) + 24px);
    z-index: 1;

    /* Dimensions */
    width: 380px;

    /* Position */
    margin: 0 0 24px 0;
    padding: 32px;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 12px;
  }
}

.detailsInfo {
  display: flex;
  align-items: center;
}

.detailsAspectWrapper {
  position: relative;

  /* Style */
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: var(--borderRadius);
}

.rootForImage {
  object-fit: cover;

  @media (--viewportLarge) {
    width: 100px;
    height: 75px;
    border-radius: var(--borderRadius);
  }
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-left: 24px;
  }
}

.detailsTitle {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;

  margin-top: 0;
  margin-bottom: 0;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionError {
  composes: h5 from global;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  @media (--viewportLarge) {
    padding-top: 24px;
    margin-top: 24px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--matterColorNegative);
  }
}

.bookingPanelBox {
  position: relative;
}

.filtersTitle {
  margin-top: 0;
  margin-bottom: 12px;

  /* Font */
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.quickRepliesTitle {
  composes: marketplaceModalTitleStyles from global;
}

.quickReplies {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--matterColorNegative);
}

.quickReplyTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.quickReplyMessage {
  margin-top: 4px;
  margin-bottom: 0;

  /* Text layout */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.quickReplyButton {
  font-size: 14px;
  margin-top: 8px;
  width: fit-content;
  border-color: var(--matterColorNegative);

  height: 40px;
  padding: 0 18px;

  &:hover {
    border-color: var(--matterColor);
  }
}

.returnLinkContainer {
  padding: 24px 24px 0 24px;

  @media (--viewportLarge) {
    padding: 0;
    margin-bottom: 24px;
  }
}

.returnLink {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;

  color: var(--matterColor) !important;
}

.returnLinkIcon {
  margin-top: 1px;
}
