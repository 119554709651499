@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Position */
  position: relative;

  /* Style */
  border-radius: var(--borderRadiusMedium);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.listingLink {
  color: var(--matterColor);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 12px;

  /* Style */
  background-color: var(--matterColorLight);
  border-left: 1px solid var(--matterColorNegative);
  border-right: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.details,
.detailsItem,
.listingOrganization {
  /* Font */
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColor);
}

.detailsItem {
  &:not(:last-child) {
    &:after {
      content: '/';
      margin: 0 4px;
    }
  }
}

.listingOrganization {
  margin-top: 6px;
  font-weight: var(--fontWeightMedium);
}

.ratingDetails {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-top: 12px;

  /* Font */
  color: var(--matterColorLight);

  /* Style */
  background-color: var(--matterColorBright);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    width: fit-content;
  }

  @media (--viewportLarge) {
    width: 100%;
  }
}

.ratingDetailsItem {
  display: flex;
  align-items: center;

  &:nth-child(2) {
    @media (--viewportMedium) {
      display: none;
    }

    @media (--viewportLarge) {
      display: flex;
    }
  }
}

.ratingDetailsItemIcon {
  height: 14px;
  width: 14px;

  margin-top: -2px;
  margin-right: 5px;

  fill: var(--attentionColor);
}

.ratingDetailsItemIconRed {
  fill: var(--marketplaceColor);
}

.ratingDetailsItemText {
  /* Font */
  font-size: 12px;
  line-height: 18px;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}
