@import '../../styles/customMediaQueries.css';

.root {
  /* Position */
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 1;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  &:disabled {
    opacity: 0.5;
  }

  @media (--viewportMedium) {
    &:hover {
      & .watchlistIconStroke {
        stroke: none;
        fill: var(--matterColorLight);
        fill-opacity: 0.32;
      }
      & .watchlistIconShadow {
        stroke: var(--matterColorLight);
        stroke-opacity: 0.32;
      }
    }
  }
}

.rootFilled {
  & .watchlistIconStroke {
    fill: var(--attentionColor);
    stroke: var(--attentionColor);
    stroke-opacity: 1;
  }

  @media (--viewportMedium) {
    &:hover {
      & .watchlistIconStroke {
        fill: rgb(255, 170, 0);
        stroke: rgb(255, 170, 0);
        stroke-opacity: 0.7;
      }
    }
  }
}

.watchlistIcon {
  fill: none;
}

.plusIcon,
.spinnerIcon,
.checkmarkIcon {
  /* Position */
  position: absolute;
  top: calc(50% - 11px);
}

.plusIcon,
.spinnerIcon {
  /* Size */
  height: 14px;
  width: 14px;
}

.spinnerIcon,
.checkmarkIcon {
  fill: var(--matterColorLight);
  stroke: var(--matterColorLight);
}
