@import '../../styles/customMediaQueries.css';

.root {
  position: relative;

  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  /* Dimensions */
  width: 100%;
  height: 100%;
  padding-top: 32px;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportLarge) {
    padding-top: 0;
  }
}

.content {
  height: calc(100% - var(--buttonRootHeight));
  overflow-y: auto;
  padding: 0 24px calc(var(--buttonRootHeight) + 48px);

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

.contentWrapper {
  max-width: var(--EditListingPage_panelWidth);
  width: 100%;
  margin: 0 auto;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 12px;
}

.title {
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 42px;
  }
}

.subTitle {
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorAnti);

  margin-top: 6px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
    line-height: 32px;
  }
}

.sectionTitle {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  color: var(--matterColorAnti);

  margin: 0;
}

.listingLink {
  color: var(--marketplaceVariationColor);
}

.editPlanButton {
  composes: h5 from global;
  margin: 0;
  padding-top: 5px;
}

.editPlanIcon {
  margin: 0px 3px 4px 0;
}

.week {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-bottom: 23px;

  & > .weekDay {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.weekDay {
  composes: marketplaceTabNavHorizontalFontStyles from global;
  display: flex;
  flex-direction: row;
  padding: 24px 24px 23px 24px;
}

.dayOfWeek {
  width: 100px;
}

.blockedWeekDay {
  background-color: #f3f3f3;
  & > .dayOfWeek {
    color: var(--matterColorAnti);
  }

  &:hover > .dayOfWeek {
    color: var(--matterColor);
  }
}

.entries {
}

.entry {
  display: block;
}

.exceptionsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noExceptions {
  padding: 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border: solid 1px var(--matterColorNegative);
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);
}

.noExceptionsText {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin: 0;
  padding: 0;
}

.spinner {
  height: 24px;
  width: 24px;

  stroke: var(--marketplaceVariationColor);
}

.exceptions {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);

  margin-top: 0;
  margin-bottom: 0;
}

.exception {
  background-color: var(--matterColorLight);
  border-bottom: solid 1px var(--matterColorNegative);

  &:first-child {
    border-top-left-radius: var(--borderRadiusMedium);
    border-top-right-radius: var(--borderRadiusMedium);
  }
}

.exceptionSubscription {
  background-color: var(--experienceAvailabilityColorMembers);
}

.exceptionHeader {
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 12px 24px 2px;
}

.exceptionFooter {
  /* Font */
  composes: h5 from global;
  color: var(--matterColorAnti);

  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 6px;

  margin-top: 0;
  margin-bottom: 0;

  padding: 0 24px 16px 44px;
}

.exceptionAvailability {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: auto;
  padding-right: 12px;
}

.exceptionAvailabilityDot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 12px;
  background-color: var(--failColor);
}

.isAvailable {
  background-color: var(--successColor);
}

.exceptionAvailabilityStatus {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 0;
}

.deleteExceptionButton {
  padding: 0;
  border: 0;
  cursor: pointer;

  &:hover .deleteIcon {
    opacity: 1;
  }
}

.deleteIcon {
  width: 15px;
  height: 15px;

  /* Effect */
  opacity: 0.6;
}

.deleteSpinnerIcon {
  height: 15px;
  width: 15px;

  stroke: var(--marketplaceVariationColor);
}

.addExceptionButton {
  /* Font */
  color: var(--marketplaceVariationColor);
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  width: 100%;
  padding: 12px 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border-left: 1px solid var(--matterColorNegative);
  border-right: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);

  /* Effect */
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }
}

.exceptionLegends {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 12px;
}

.exceptionLegend {
  display: flex;
  align-items: center;

  margin-right: 18px;
  &:last-child {
    margin-right: 0;
  }
}

.exceptionLegendColor {
  /* Size */
  height: 20px;
  width: 20px;

  /* Style */
  background-color: var(--experienceAvailabilityColor);
  border: solid 2px var(--matterColorNegative);
  border-radius: var(--borderRadius);

  margin-right: 8px;
}

.exceptionLegendColorMembers {
  border-color: var(--experienceAvailabilityColorMembers);
  background-color: var(--experienceAvailabilityColorMembers);
}

.exceptionLegendText {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;
}

.goToNextTabButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

.timeRange,
.separator,
.repeat {
  display: inline;
}

.repeatIcon {
  height: 12px;
  width: 12px;

  margin-top: -2px;
  margin-right: 8px;
}

.repeatIcon g {
  stroke: var(--attentionColor);
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  height: 100%;

  padding-left: 0;
  padding-right: 0;
}

.error {
  color: var(--failColor);
}

.submitAndPreviewButton {
  display: flex;
  align-items: center;
}

.submitButtonRoot {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* Size */
  width: 100%;
  padding: 16px 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    position: absolute;
    padding: 20px 24px;
  }
}

.backButton {
  composes: editWizardButtonRoot from '../../components/Button/Button.module.css';

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);

  /* Font */
  color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorBright);
    color: var(--matterColor);
    text-decoration: none;
  }
}

@media (--viewportMedium) {
  .sectionHeader {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .sectionTitle {
    margin-top: 21px;
    margin-bottom: 17px;
    padding: 0;
  }

  .weekDay {
    padding-top: 22px;
    padding-bottom: 25px;
    padding-right: 24px;
    padding-left: 24px;
    cursor: pointer;

    &:hover {
      color: var(--matterColorDark);
    }
  }

  .dayOfWeek {
    width: 200px;
  }

  .modalContainer {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}

@media (--viewportLarge) {
  .goToNextTabButton {
    display: inline-block;
    width: 241px;
    margin-top: 62px;
  }
}
