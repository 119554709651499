@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: 48px;

  @media (--viewportLarge) {
    margin-top: 96px;
  }
}

.bookingInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  text-align: center;
  line-height: 24px;

  margin: 0;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}
