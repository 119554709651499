:root {
  --quantityButtonColor: #b0b0b0;
  --quantityIconColor: #717171;
}

.popupSize {
  padding: 24px 30px;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.quantityName {
  margin-top: 0;
  margin-bottom: 0;
}

.quantityInput {
  display: none;
}

.quantityValue {
  user-select: none;
}

.quantityButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.quantityButton {
  /* Font */
  color: var(--quantityIconColor);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: 32px;
  width: 32px;
  padding: 0;

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--quantityButtonColor);
  border-radius: 50%;

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    border-color: var(--matterColor);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--matterColorLight);
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
  }
}

.quantityIcon {
  stroke: currentColor;
  stroke-width: 5;
}
