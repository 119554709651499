@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.inputWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: relative;
}

.inputSpinner {
  position: absolute;
  right: 12px;

  /* Size */
  height: 18px;
  width: 18px;

  /* Color */
  stroke: var(--marketplaceVariationColor);
}

.email {
  margin-bottom: 24px;
}

.emailInput {
  width: 100%;
}

.checkboxInputContainer {
  margin-top: 24px;
}

.checkboxInputSmallText {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  line-height: 20px;

  /* Margin */
  margin-top: 8px;
  margin-bottom: 0;
}

.yearFields {
  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 24px;

  margin-top: 24px;
}

.yearField {
  width: 100%;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  /* Style */
  composes: buttonColorsVariation from global;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 0;
  }
}
