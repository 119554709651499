@import '../../styles/customMediaQueries.css';

:root {
  --seekHeight: 5px;
}

.root {
  position: relative;
  width: 100%;

  /* Style */
  background-color: var(--matterColorAnti);
  border-radius: var(--borderRadiusMedium);

  /* Effect */
  cursor: pointer;
}

.disabledRoot,
.loadingRoot,
.errorRoot {
  cursor: default;
  pointer-events: none;
}

.disabledRoot:after {
  content: '';

  /* Position */
  position: absolute;
  top: 0;
  left: 0;

  /* Size */
  height: 100%;
  width: 100%;

  /* Style */
  background-color: rgba(221, 221, 221, 0.5);
  border-radius: var(--borderRadiusMedium);
}

.fullscreenRoot {
  background-color: var(--matterColorDark);
  border-radius: 0;
}

video::-webkit-media-controls-enclosure {
  display: none;
}

.videoPlayerWrapper {
  /* Style */
  border-radius: var(--borderRadiusMedium);
}

.aspectPadding {
  position: relative;
}

.fullscreenRoot .videoPlayerWrapper {
  height: 100%;
  width: 100%;

  /* Style */
  object-fit: cover;
}

.fullscreenRoot .aspectPadding {
  /* Layout */
  height: 100%;
  width: 100%;
  padding-bottom: unset !important;
}

.videoPlayer {
  /* Position */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* Layout */
  width: 100%;
  height: 100%;

  /* Style */
  object-fit: cover;
  border-radius: var(--borderRadiusMedium);
}

.fullscreenRoot .videoPlayerWrapper,
.fullscreenRoot .videoPlayer {
  padding-bottom: 0 !important;
  border-radius: 0;
}

.fullscreenRoot .videoPlayer {
  object-fit: contain;
}

.controller {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  /* Position */
  position: absolute;
  bottom: 0;
}

.disabledRoot .controller {
  pointer-events: none;
}

.controllerOverlay {
  /* Position */
  position: absolute;
  bottom: 0;

  /* Layout */
  height: 58px;
  width: 100%;

  /* Style */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.592) 8.1%,
    rgba(0, 0, 0, 0.573) 15.5%,
    rgba(0, 0, 0, 0.537) 22.5%,
    rgba(0, 0, 0, 0.494) 29%,
    rgba(0, 0, 0, 0.443) 35.3%,
    rgba(0, 0, 0, 0.39) 41.2%,
    rgba(0, 0, 0, 0.33) 47.1%,
    rgba(0, 0, 0, 0.27) 52.9%,
    rgba(0, 0, 0, 0.21) 58.8%,
    rgba(0, 0, 0, 0.157) 64.7%,
    rgba(0, 0, 0, 0.106) 71%,
    rgba(0, 0, 0, 0.063) 77.5%,
    rgba(0, 0, 0, 0.027) 84.5%,
    rgba(0, 0, 0, 0.008) 91.9%,
    rgba(0, 0, 0, 0)
  );
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);

  /* Effect */
  transition: var(--transitionStyleButton);
}

.controller,
.controllerOverlay {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.bigPlayButtonRoot .controller,
.bigPlayButtonRoot .controllerOverlay {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.fullscreenRoot .controllerOverlay {
  /* Controls height + seek bar height */
  height: calc(64px + var(--seekHeight));
}

.controls {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 24px 8px 18px;

  /* Style */
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
}

.fullscreenRoot .controls {
  padding: 24px 8px;
}

.fullscreenRoot .controllerOverlay,
.fullscreenRoot .controls {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.controlsLeft,
.controlsRight {
  display: flex;
  align-items: center;
}

.controlButton {
  /* Position */
  position: relative;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  padding: 0 8px;

  &:before {
    content: '';
    display: block;

    /* Position */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* Size */
    width: 32px;
    height: 32px;

    /* Style */
    border-radius: 50%;
    background-color: transparent;
  }
  &:hover {
    &:before {
      background-color: var(--matterColor);
    }
  }
}

.controlsText {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorLight);

  margin-top: 0;
  margin-bottom: 0;
  padding-left: 8px;
}

.controlIcon {
  /* Position */
  position: relative;

  height: 16px;
  width: 16px;
}

.fullscreenIcon {
  height: 15px;
  width: 15px;
}

.fullscreenRoot .fullscreenIcon {
  height: 16px;
  width: 16px;
}

.fullscreenButton {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.bigPlayButton {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 1;
}

.bigPlayIcon {
  height: 24px;
  width: 24px;
}

.seekSlider {
  /* Position */
  position: relative;

  /* Layout */
  display: none;

  /* Size */
  max-width: calc(960px - 32px);
  width: calc(100% - 32px);
  height: var(--seekHeight);

  @media (--viewportMedium) {
    &:hover {
      /* Animate track */
      height: 7px;
      /* Animate thumb */
      font-size: 17px;
    }
  }
}

.fullscreenRoot .seekSlider {
  /* Layout */
  display: flex;
  align-items: center;

  /* Show */
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.seekTrack {
  position: absolute;

  height: 100%;
  width: 100%;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);

  /* Effect */
  opacity: 0.8;
}

.seekFilledTrack {
  position: absolute;
  height: 100%;

  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);
}

.seekThumb {
  /* Position */
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;

  /* Size */
  width: 1em;
  height: 1em;

  /* Style */
  background-color: var(--marketplaceColor);
  border-radius: 50%;
}

.tag {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorLight);

  /* Position */
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  width: fit-content;
  padding: 6px 16px;
  margin: 0 auto;

  /* Style */
  background-color: var(--matterColor);
  border-radius: var(--topbarButtonBorderRadius);
}
