@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 72px;
  padding-bottom: 48px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    margin-top: 24px;
  }
}

.modalRoot {
  margin-top: var(--topbarHeightDesktop);
}

.modalContent {
  flex-grow: 1;
}
