@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.tableWrapper {
  position: relative;
  background-color: var(--tableBg);
  border: 1px solid var(--matterColorNegative);
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);

  /* Scroll */
  overflow-x: auto;
  @media (--viewportLarge) {
    overflow-x: unset;
  }
}

.headings {
}

.heading {
  margin: 0;
  padding: 0;

  padding-top: 16px;
  padding-bottom: 16px;

  /* Font */
  text-align: left;

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
}

.headingText {
  font-weight: var(--fontWeightMedium);
}

.content {
  margin: 0;
  padding: 0;
}

.row {
  /* Font */
  color: var(--matterColor);
  text-decoration: none !important;

  /* Style */
  background-color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    &:last-child {
      border-bottom: 0;
    }
  }

  @media (--viewportLarge) {
    &:hover {
      background-color: var(--matterColorLightTransparent);
    }
  }
}

.rowItem {
  /* Font */
  color: var(--matterColor);
  text-decoration: none !important;

  padding-top: 16px;
  padding-bottom: 16px;

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
}
