@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-right: 0;
    background: none;
    border-radius: none;
  }
}

.avatar {
  position: relative;
  flex-shrink: 0;

  @media (--viewportMedium) {
    height: 96px;
    width: 96px;
  }
}

.content {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.heading {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    text-decoration: underline;
  }
}

.infoText {
  /* Font */
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);

  margin-top: 0;
  margin-bottom: 0;
}

.infoTextWithMargin {
  margin-top: 6px;
}
