@import '../../styles/customMediaQueries.css';

:root {
  --buttonRootHeight: 77px;
}

.root {
  /* Dimensions */
  width: 100%;
  height: 100%;
  padding-top: 32px;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportLarge) {
    padding-top: 0;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 42px;
  }
}

.subTitle {
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorAnti);

  margin-top: 6px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
    line-height: 32px;
  }
}

.error {
  color: var(--failColor);
}

.stripeRequirementsMissing {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  /* Style */
  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
  border-radius: var(--borderRadiusMedium);
  padding: 18px 24px;

  margin-bottom: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }
}

.stripeRequirementsMissingMessage {
  composes: h4 from global;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  padding: 0;
  height: 42px;
  width: 100%;

  /* Style */
  background-color: var(--attentionColor);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    width: 300px;
    max-width: 110px;

    &:hover,
    &:focus {
      background-color: var(--attentionColor);
    }
  }
}

.content {
  height: calc(100% - var(--buttonRootHeight));
  overflow-y: auto;
  padding: 0 24px calc(var(--buttonRootHeight) + 48px);

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

.contentWrapper {
  max-width: var(--EditListingPage_panelWidth);
  width: 100%;
  margin: 0 auto;
}

.formMargins {
  margin-top: 24px;
}

.field {
  composes: formMargins;
}

.durationFields {
  display: flex;
  align-items: center;

  /* Position */
  position: relative;
}

.durationLengthField {
  flex: 1;

  @media (--viewportMedium) {
    & input {
      padding-right: 124px;
    }
  }
}

.durationTypeField {
  position: absolute;

  /* Make sure to avoid conflict with parent
     border */
  top: 1px;
  right: 1px;

  & select {
    border: 0;
  }
}

.membershipsWrapper {
  display: flex;
  flex-direction: column;
}

.memberships {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (--viewportMedium) {
    gap: 16px;
  }
}

.noMemberships {
  /* Font */
  color: var(--matterColor);
  text-align: left;
  text-decoration: none !important;

  /* Layout */
  width: 100%;
  padding: 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  /* Effect */
  cursor: pointer;

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }
}

.membership {
  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }
}

.membershipHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;
}

.hiddenMembershipHeader {
  display: none;
}

.membershipForm {
  display: none;

  /* Layout */
  padding: 24px;
}

.visibleMembershipForm {
  display: block;
}

.membershipTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.manage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.manageButton {
  display: flex;

  /* Change color of an icor on hover */
  &:hover {
    & .manageButtonIcon path {
      stroke: var(--marketplaceVariationColor);
    }
  }
}

.manageButtonIcon {
  height: 20px;
  width: 20px;
}

.manageButtonIcon path {
  stroke: var(--matterColor);

  /* Effect */
  transition: all var(--transitionStyleButton);
}

.listRenderer {
  /* Layout */
  margin-top: 32px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.addMembershipButton {
  /* Font */
  font-size: 14px;

  /* Layout */
  height: 42px;
  width: fit-content;
  align-self: flex-end;
  padding: 0 16px;

  margin-bottom: 24px;

  /* Style */
  composes: buttonColorsVariation from global;
}

.backToMembershipsButton {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;

  margin-bottom: 12px;
}

.backButton {
  composes: editWizardButtonRoot from '../../components/Button/Button.module.css';

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColor);

  /* Font */
  color: var(--matterColor);

  &:hover {
    background-color: var(--matterColorBright);
    color: var(--matterColor);
    text-decoration: none;
  }
}

.actionButtons {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.actionButton {
  /* Font */
  font-size: 14px;

  /* Size */
  padding-top: 10px;
  padding-bottom: 10px;
  width: fit-content;
}

.actionButtonSave {
  /* Style */
  composes: buttonColorsVariation from global;
}

.submitAndPreviewButton {
  display: flex;
  align-items: center;
}

.submitButtonRoot {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* Size */
  width: 100%;
  padding: 16px 24px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    position: absolute;
    padding: 20px 24px;
  }
}

.submitButton {
}
