@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

/* SideNav */
.sideNav {
  @media (--viewportMedium) {
    /* Position */
    position: fixed;
    left: 0;
    z-index: 1;

    width: auto;
    height: 100%;
    min-width: var(--dashboardSideNavWidth);
    max-width: var(--dashboardSideNavWidth);
    padding: 48px 12px;
    background-color: var(--matterColorBright);
    border-right: 1px solid var(--matterColorNegative);
    border-bottom: 0;
  }
}

@keyframes sideNavItemsAnimation {
  from {
    transform: translateX(calc(var(--TabNav_editListingLinkWidth) * -1));
  }
  to {
    transform: translateX(0);
  }
}

.sideNavItems {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Size */
  padding: 16px 0;
  height: 100%;
  width: 100%;

  /* Scroll */
  overflow-y: hidden;
  overflow-x: auto;

  /* Style */
  background-color: var(--matterColorBright);
  border-bottom: 1px solid var(--matterColorNegative);
  border-top: 1px solid var(--matterColorNegative);

  &:before {
    content: '';
    display: block;
    background-color: transparent;
    border: 12px solid transparent;
  }
  &:after {
    content: '';
    display: block;
    background-color: transparent;
    border: 12px solid transparent;
  }

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: unset;

    overflow-x: hidden;
    height: unset;
    width: unset;
    padding: 0;
    background-color: unset;
    border-top: 0;
    border-bottom: 0;

    /* Animation */
    animation-name: sideNavItemsAnimation;
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    &:before,
    &:after {
      display: none;
    }
  }
}

.sideNavItem {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  /* Font */
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  text-decoration: none !important;
  white-space: nowrap;
  text-transform: capitalize;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);

  height: 38px;
  padding: 0 12px;

  &:not(:last-child) {
    margin-right: 12px;
  }
  &:last-child {
    margin-right: 0;
  }

  /* Effect */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    height: 44px;
    width: 100%;

    /* Style */
    background-color: var(--matterColorBright);

    margin-right: 0;
    padding: 0 12px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:hover {
      color: var(--matterColorLight);
      background-color: var(--marketplaceVariationColor);

      & .sideNavIcon path {
        stroke: var(--matterColorLight);
      }
    }
  }
}

.sideNavItemAudience {
  &:hover {
    background-color: var(--matterColor);
  }
}

.sideNavItemActive {
  /* Font */
  color: var(--matterColorLight);

  /* Style */
  background-color: var(--marketplaceVariationColor);
  border-radius: var(--borderRadiusMedium);

  /* Effect */
  cursor: default !important;
}

.sideNavItemAudienceActive {
  background-color: var(--matterColor);
}

.sideNavItemActive .sideNavIcon path {
  stroke: var(--matterColorLight);
}

.sideNavItemDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.sideNavIcon {
  fill: none;

  & path {
    stroke: var(--matterColor);
    stroke-width: 1.3;
  }
}

/* SideContent */
.sideContent {
  /* Position */
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    padding-left: var(--dashboardSideNavWidth);
  }
}

.inboxIconDotFilled {
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor) !important;
}

.gettingStartedTabTitle {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 12px;
}

.closeButton {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: 18px;
  width: 18px;

  /* Style */
  background: var(--matterColorBright);
  border-radius: 50%;

  &:hover {
    background-color: var(--matterColorLight);
  }
}

.closeIcon {
  /* Size */
  height: 7px;
  width: 7px;

  /* Style */
  fill: var(--matterColor);
  stroke: none;
}
