@import '../../styles/customMediaQueries.css';

:root {
  --inputHeight: 50px;
  --inputWidth: 280px;

  --submitButtonIconWidth: 38px;
}

.root {
  /* Layout */
  display: flex;
  align-items: center;
  position: relative;
}

.field {
  flex: 1;
}

.fieldInput {
  composes: marketplaceInputFontStyles from global;
  font-size: 14px;
  line-height: 24px;

  /* Size */
  height: var(--inputHeight);
  width: var(--inputWidth);
  padding: 0 calc(36px + var(--submitButtonIconWidth)) 0 16px;

  /* Style */
  border: none;
  box-shadow: none;
  border-radius: 20px;
}

.submitButton {
  /* Position */
  position: absolute;
  top: 0;
  right: 0;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px 0 12px;

  /* Style */
  background-color: transparent !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  /* Size */
  height: var(--inputHeight);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative) !important;
  }
}

.submitButtonIcon {
  width: var(--submitButtonIconWidth);
}
.submitButtonIcon path {
  fill: var(--marketplaceFooterColor);
}

.submitButtonSpinner {
  width: 20px;
  height: 20px;
  stroke: var(--marketplaceFooterColor);
  stroke-width: 3px;
}
