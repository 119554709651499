@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.commentAs {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 8px;
}

.comment {
  min-height: 112px;

  /* Style */
  border: 1px solid #edeff1;
  border-bottom: none;
  padding: 8px 16px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.commentInput {
  /* Font */
  composes: marketplaceSmallFontStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  border: none;

  min-height: 112px;
  padding: 0;

  /* Animation */
  transition: none;

  &:hover,
  &:focus {
    border: none;
  }
}

.bottomWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  /* Style */
  background-color: #f6f7f8;
  border-left: 1px solid #edeff1;
  border-right: 1px solid #edeff1;
  border-bottom: 1px solid #edeff1;
  padding: 8px 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @media (--viewportMedium) {
    justify-content: flex-end;
    padding: 6px 8px;
  }
}

.resetButton {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColor);
  }
}

.submitButton {
  margin-top: 0;
  margin-bottom: 0;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  max-width: auto;
  min-height: 38px;
  padding: 0 12px;

  /* Style */
  background-color: var(--marketplaceColor);

  &:disabled {
    color: var(--matterColorAnti);

    &:hover {
      background-color: var(--matterColorNegative);
    }
  }

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.spinnerIcon {
  width: 18px;
  height: 18px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}
