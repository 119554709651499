@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.submitButton {
  flex-shrink: 0;
  margin-top: 48px;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
  }
}
