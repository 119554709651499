@import '../../styles/customMediaQueries.css';

.field {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.fieldItem {
  padding: 0;
}

.categoryOptions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;

  margin-top: 4px;
}

.categoryOption {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);

  /* Animation */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorBright);
    border-color: var(--marketplaceColor);
    box-shadow: var(--boxShadowLight);
  }
}

.categoryOptionSelected {
  background-color: var(--matterColorBright);
  border-color: var(--marketplaceColor);
  box-shadow: var(--boxShadow);
}

.categoryOptionLabel {
  composes: h5 from global;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.radioButton {
  display: none;
}
