.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;

  /* Horizontal scroll */
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    /* Remove scrollbar */
    width: 0;
    height: 0;
  }
}
