@import '../../styles/customMediaQueries.css';

.root {
}

.heading {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  font-size: 28px;
  line-height: 36px;

  margin-top: 32px;
}

.text {
  margin-top: 6px;
  margin-bottom: 0;
}

.field {
  margin-top: 24px;
  margin-bottom: 0;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
