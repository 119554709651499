@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.field {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 48px;

  & button {
    flex: 1;
  }
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
}

.deleteButton {
  display: none;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    width: 100%;
  }
}

.deleteButtonVisible {
  display: block;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.submitButton {
  margin-top: 0;
  margin-bottom: 0;
  flex-shrink: 0;

  /* Style */
  composes: buttonColorsVariation from global;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
  }
}
